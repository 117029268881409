import React from "react";
import { useTranslation } from "react-i18next";
import deep_learning from "../../assets/images/applications/deep_learning.png"
import { FaArrowRightLong } from "react-icons/fa6";

const factors = [
  { name: "HighBP", value: 7.0 },
  { name: "HighChol", value: 1.7 },
  { name: "CholCheck", value: 6.5 },
  { name: "BMI", value: 5.8 },
  { name: "Smoker", value: 1.4 },
  { name: "Stroke", value: 4.9 },
  { name: "HeartDiseaseorAttack", value: 9.0 },
  { name: "PhysActivity", value: 4.4 },
  { name: "Fruits", value: 8.6 },
  { name: "Veggies", value: 5.3 },
  { name: "HvyAlcoholConsump", value: 4.7 },
  { name: "AnyHealthcare", value: 7.0 },
  { name: "NoDocbcCost", value: 1.7 },
  { name: "GenHlth", value: 6.5 },
  { name: "MentHlth", value: 5.8 },
  { name: "PhysHlth", value: 1.4 },
  { name: "DiffWalk", value: 4.9 },
  { name: "Sex", value: 9.0 },
  { name: "Age", value: 4.4 },
  { name: "Income", value: 8.6 },
  { name: "Education", value: 5.3 },
];

const TP = 190000
const TN = 170000
const FP = 30000
const FN = 10000
const Accuracy = 100*(TP+TN)/(TP+FP+TN+FN)
const Precision = 100*(TP)/(TP+FP)
const Recall = 100*(TP)/(TP+FN)
const F1score = 2*Recall*Precision/(Recall+Precision)

const dataArr = [
  { name: "True Positives", value: 100*TP/(TP+FP), count: TP, total: TP+FP, fill1: "bg-green-100", fill2: "bg-green-600" },
  { name: "True Negatives", value: 100*TN/(TN+FN), count: TN, total: TN+FN, fill1: "bg-green-100", fill2: "bg-green-600" },
  { name: "False Positives", value: 100*FP/(TP+FP), count: FN, total: TP+FP, fill1: "bg-red-100", fill2: "bg-red-600" },
  { name: "False Negatives", value: 100*FN/(TN+FN), count: FP, total: TN+FN, fill1: "bg-red-100", fill2: "bg-red-600" },
]

const DiagnosticsModel = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col p-6">

      <div className="flex   justify-start">

        <div className="pl-8 pb-2 border-[2px] border-[#e4e7ec] rounded-[10px] min-h-[191px]">
          <h2 className="text-center text-blue-600 text-2xl font-bold mb-4">Model</h2>
          <div className="flex justify-center">
            <div className="flex-col">
              {factors.map((val) => {
                return (
                  <div className="items-center text-center flex justify-end pt-2 text-sky-600 bold">
                    {val.name}
                    <div className="pl-1"></div>
                    <FaArrowRightLong style={{ color: "#075985" }} />
                  </div>
                );
              })}
              <div className="w-[6px] h-[10px]"></div>
            </div>
            <div
              className={`w-[200px] bg-white-200 border-sky-800 border-4 items-center text-center flex justify-center text-xl font-bold rounded-lg shadow-xl`}
            >
              <img
                src={deep_learning}
                alt="deep_learning"
                className="h-32"
              />
            </div>
            <div className="items-center text-center flex justify-center">
              <FaArrowRightLong style={{ color: "#075985" }} />
              <div className="ml-2 mr-2 text-sky-600 bold">Fraudulent</div>
            </div>
          </div>
        </div>

        <div className="pl-16"></div>

        <div className="border-[2px] border-[#e4e7ec] rounded-[10px] min-h-[191px]">

          <h2 className="text-center text-blue-600 text-2xl font-bold mb-4">{t("Performans")}</h2>

          <div className="p-2 w-full flex justify-between items-center text-center ">
            
            <div className="p-2  bg-orange-100 border rounded-xl shadow min-w-[100px]">
              <p className="text-sky-700 text-[20px]">{t("Accuracy")}</p>
              <h1 className="text-emerald-900 text-[24px]">{Accuracy.toFixed(2)}%</h1>
            </div>

            <div className="p-2 bg-orange-100 border  rounded-xl shadow min-w-[100px] ml-2">
              <p className="text-sky-700 text-[20px]">{t("Precision")}</p>
              <h1 className="text-emerald-900 text-[24px]">{Precision.toFixed(2)}%</h1>
            </div>

            <div className="p-2 bg-orange-100 border  rounded-xl shadow min-w-[100px] ml-2">
              <p className="text-sky-700 text-[20px]">{t("Recall")}</p>
              <h1 className="text-emerald-900 text-[24px]">{Recall.toFixed(2)}%</h1>
            </div>

            <div className="p-2 bg-orange-100 border  rounded-xl shadow min-w-[100px] ml-2">
              <p className="text-sky-700 text-[20px]">{t("F1score")}</p>
              <h1 className="text-emerald-900 text-[24px]">{F1score.toFixed(2)}%</h1>
            </div>
            
          </div>

          <div className="w-full h-full overflow-auto center flex-col p-[10px] gap-[10px] ">
            {dataArr.map((item, i) => (
              <div
                key={i + "progs"}
                className="mb-4 w-full flex flex-col gap-[5px] text-[#000000] pb-4 bg-slate-100 border p-2 rounded-[10px] shadow"
              >
                <div className="w-full flex justify-between items-center">
                  <p className="text-[20px] font-[500]">{item.name}</p>
                  <p className="text-[20px] font-[500]">{item.value.toFixed(2)}%</p>
                </div>

                <div className="w-full justify-between flex items-center">
                  <div className={`relative w-full ${item.fill1} h-[15px] rounded-full overflow-hidden`}>
                    <div className={`absolute ${item.fill2} h-full rounded-full top-0 left-0`} style={{ width: item.value + "%" }}></div>
                  </div>
                  <div className="text-[20px] font-[500] pl-3 pb-1 ">
                    {item.count}/{item.total}
                  </div>
                </div>
              </div>
              
            ))}
          </div>

        </div>

        <div className="pl-16"></div>

        <div className="pl-32 border-[2px] border-[#e4e7ec] rounded-[10px] min-h-[191px]">
          <h2 className="text-blue-600 text-2xl font-bold mb-4">{t("Faktör_Etki_Değerleri")}</h2>
          {factors.map((factor, index) => (
            <div key={index} className="flex items-center mb-2">
              <div className="w-40 text-gray-700 text-sm">{factor.name}</div>
              <div className="w-32 bg-gray-200 rounded-full h-4 ml-2 relative">
                <div
                  className="bg-orange-400 h-4 rounded-full"
                  style={{ width: `${factor.value * 10}%` }}
                ></div>
              </div>
              <div className="ml-2 text-gray-700 font-medium">{factor.value}%</div>
            </div>
          ))}
        </div>

      </div>

    </div>
  );
};

export default DiagnosticsModel;
