import React from "react";
import { useTranslation } from "react-i18next";
import PageHeaderSolutions from "../components/PageHeaderSolutions";
import { CaretRightFilled } from "@ant-design/icons";
// import sales_prediction from "../assets/images/applications/sales_prediction.jpg";
import EcommerceBanner from "../appareas/EcommerceBanner";
import SalesForecastingChart from "./charts/SalesForecastingChart"
import { FaArrowsAltH } from "react-icons/fa";

const SalesForecasting = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeaderSolutions></PageHeaderSolutions>
      <section className="mt-24 w-full h-full relative bg-center bg-cover p-5 border-2 border-blue-900 rounded-lg bg-white">
        <div> <EcommerceBanner></EcommerceBanner> </div>
        <div className="items-center text-center">
          <h3 className="mt-2 text-blue-900 text-4xl font-semibold mb-2">
            {t("sales_forecasting")}
          </h3>
        </div>

        <p className="text-2xl">
          <CaretRightFilled />
          {t("sales_forecasting_description1")}
        </p>
        <p className="text-2xl">
          <CaretRightFilled />
          {t("sales_forecasting_description2")}
        </p>

        {/* <img src={sales_prediction} width={1200} height={1200} alt="sales_prediction" /> */}
          
          <div className="flex justify-center items-center">

          <div className="mr-4 mt-8 text-2xl text-center w-[15%] h-[5%] border-4 rounded-lg">
            DATABASE
          </div>

          <div className="flex flex-col">
          <div className="mr-4 text-2xl text-center w-[5%] h-[5%] ">
            API
          </div>

          <div className="mr-4 text-4xl text-center "> 
          <FaArrowsAltH></FaArrowsAltH>
            </div>

          </div>



          <div className="mt-24 w-[50%] rounded-xl">
            <SalesForecastingChart></SalesForecastingChart>
          </div>

          <div className="flex flex-col">
          <div className="ml-4 text-2xl text-center w-[5%] h-[5%] ">
            API
          </div>

          <div className="ml-4 text-4xl text-center "> 
          <FaArrowsAltH></FaArrowsAltH>
            </div>

          </div>

          <div className="ml-4 mt-8 text-2xl text-center w-[5%] h-[5%] border-4 rounded-lg">
            ERP
          </div>

          </div>
          


              
      </section>
    </>
  );
};

export default SalesForecasting;
