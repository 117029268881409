import React from "react";
import AlarmRecordsChart from "./AlarmRecordsChart"
import { useTranslation } from "react-i18next";
const AlarmAnalysisDataset = () => {
  const { t } = useTranslation();

  const data = [
    ["1","26/10/2022 08:59:51","DBASF017","FITE12-11194-BOREN-02","npe.dds.VfxSessionDown"],
    ["2","26/10/2022 08:59:52","DBASF017","EATE08-92090-SIVMY-01","equipment.DDMRxOpticalPowerHighWarning"],
    ["3","26/10/2022 09:00:57","DBASF017","EDTE07-51119-FLOST-01","npe.dds.VfxSessionDown"],
    ["4","26/10/2022 09:00:01","DBASF017","EVTE07-81625-VNPLZ-01","npe.dds.VfxSessionDown"],
    ["5","26/10/2022 09:00:05","DBASF017","EBTE08-18573-CROMC-05","equipment.LinkDown"],
    ["6","26/10/2022 09:00:10","DBASF017","EBTE08-12336-ERTES-01","npe.dds.VfxSessionDown"],
    ["7","26/10/2022 09:00:14","DBASF017","FMTE07-20866-BEMFV-01","npe.dds.VfxSessionDown"],
    ["8","26/10/2022 09:00:18","DBASF017","EVTE08-90680-MUSDO-01","equipment.DDMRxOpticalPowerHighAlarm"],
    ["9","26/10/2022 09:00:19","DBASF017","EBTE08-00093-GSEAE-01","npe.dds.VfxSessionDown"],
    ["10","26/10/2022 09:00:23","DBASF017","EBTE08-14276-UYUEA-01","npe.dds.VfxSessionDown"],
  ];

  return (
    <div className="w-[50%] p-6 flex flex-col items-center bg-gray-50">
      <div className="border border-gray-400 rounded-lg p-6 w-full bg-white shadow-lg">
        {/* Header */}
        <h2 className="text-xl font-bold text-blue-600 text-center">
          {t("Alarm_Kayıtları")}
        </h2>

        {/* Table Section */}
        <div className="overflow-x-auto">
          <table className="text-lg w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-sky-700 text-sky-100">
                <th className="border border-gray-300 p-2">No.</th>
                <th className="border border-gray-300 p-2">EVENTTIME</th>
                <th className="border border-gray-300 p-2">DATABASE</th>
                <th className="border border-gray-300 p-2">ALARMNODE</th>
                <th className="border border-gray-300 p-2">ALARMHEADER</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index} className="text-gray-700">
                  {row.map((cell, cellIndex) => (
                    <td
                      key={cellIndex}
                      className={`text-lg text-center border border-gray-300 p-2`}
                    >
                      {cell}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>

<AlarmRecordsChart></AlarmRecordsChart>

        </div>
      </div>
    </div>
  );
};

export default AlarmAnalysisDataset;
