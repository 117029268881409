import React from "react";
import { useTranslation } from "react-i18next";
const MarketBasketAnalysisDataset = () => {
  const { t } = useTranslation();
  const data = [
    ["1", "ekmek, cips, su, kola"],
    ["2", "su, ekmek, meyve, cips"],
    ["3", "peynir,ekmek, meyve, kola"],
    ["4", "zeytin, peynir, un, meyve"],
    ["5", "cips, su, ekmek, kola"],
    ["6", "ekmek, su, cips, kola"],
    ["7", "peynir, kola, cips, ekmek"],
    ["8", "çerez, peynir, meyve, ekmek"],
    ["9", "ekmek, peynir, su, meyve"],
    ["10", "un, kola, zeytin, cips, ekmek"],
    
  ]

  return (
    <div className="w-[50%] p-6 flex flex-col items-center bg-gray-50">
      <div className="border border-gray-400 rounded-lg p-6 w-full bg-white shadow-lg">
        {/* Header */}
        <h2 className="text-xl font-bold text-blue-600 text-center">{t("Veriseti")}</h2>
        
        {/* Table Section */}
        <div className="overflow-x-auto">
          <table className="text-lg w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-sky-700 text-sky-100">
              <th className="border border-gray-300 p-2">İşlem No.</th>
                <th className="border border-gray-300 p-2">İşlem</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index} className="text-gray-700">
                  {row.map((cell, cellIndex) => (
                    <td
                      key={cellIndex}
                      className={`text-lg text-center border border-gray-300 p-2`}
                    >
                      {cell}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        
      </div>

    </div>
  );
};

export default MarketBasketAnalysisDataset;
