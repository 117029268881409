import React, { PureComponent } from "react";
import {
  Bar,
  BarChart,
  XAxis,
  ReferenceLine,
  LabelList,
} from "recharts";

const AlarmRecordsChart = () => {
  const xticksTSF = [
    "26/10/2022 08:59:51",
    "26/10/2022 08:59:52",
    "26/10/2022 08:59:53",
    "26/10/2022 08:59:54",
    "26/10/2022 08:59:55",
    "26/10/2022 08:59:56",
    "26/10/2022 08:59:57",
    "26/10/2022 08:59:58",
    "26/10/2022 08:59:59",
    "26/10/2022 09:00:00",
    "26/10/2022 09:00:01",
    "26/10/2022 09:00:02",
    "26/10/2022 09:00:03",
    "26/10/2022 09:00:04",
    "26/10/2022 09:00:05",
    "26/10/2022 09:00:06",
    "26/10/2022 09:00:07",
    "26/10/2022 09:00:08",
    "26/10/2022 09:00:09",
    "26/10/2022 09:00:10",
    "26/10/2022 09:00:11",
    "26/10/2022 09:00:12",
    "26/10/2022 09:00:13",
    "26/10/2022 09:00:14",
    "26/10/2022 09:00:15",
    "26/10/2022 09:00:16",
    "26/10/2022 09:00:17",
    "26/10/2022 09:00:18",
    "26/10/2022 09:00:19",
    "26/10/2022 09:00:20",
    "26/10/2022 09:00:21",
    "26/10/2022 09:00:22",
    "26/10/2022 09:00:23",
  ];
  const valuesTS = [
    "1",
    "1",
    "0",
    "0",
    "0",
    "0",
    "1",
    "0",
    "0",
    "0",
    "1",
    "0",
    "0",
    "0",
    "1",
    "0",
    "0",
    "0",
    "0",
    "1",
    "0",
    "0",
    "0",
    "1",
    "0",
    "0",
    "0",
    "1",
    "1",
    "0",
    "0",
    "0",
    "1",
  ];

  const names = [
    "E",
    "A",
    "",
    "",
    "",
    "",
    "B",
    "",
    "",
    "",
    "T",
    "",
    "",
    "",
    "F",
    "",
    "",
    "",
    "",
    "D",
    "",
    "",
    "",
    "N",
    "",
    "",
    "",
    "R",
    "P",
    "",
    "",
    "",
    "X",
  ];

  const data = [];
  for (let ii = 0; ii < xticksTSF.length; ii++) {
    data.push({
      datetime: xticksTSF[ii].toString(),
      values: valuesTS[ii].toString(),
      name: names[ii].toString(),
    });
  }

  class CustomizedXaxisTick extends PureComponent {
    render() {
      const props = this.props;
      return (
        <g transform={`translate(${props.x},${props.y})`}>
          <text x={0} y={0} dx={-2} dy={4} textAnchor="end" transform="rotate(-90)">
            {props.payload.value}
          </text>
        </g>
      );
    }
  }

  const renderCustomizedLabel = (props) => {
    const { x, y, width, name } = props;
    return (
      <g>
        <text
          x={x + width / 2}
          y={y - 10}
          fill="#000000"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {name}
        </text>
      </g>
    );
  };

  return (
    <div className="min-w-[200] min-h-[200]">
      <BarChart
        data={data}
        width={1100}
        height={400}
        margin={{
          top: 50,
          right: 30,
          left: 20,
          bottom: 130,
        }}
      >

        <XAxis
          dataKey="datetime"
          style={{ fontSize: "1rem" }}
          tick={<CustomizedXaxisTick />}
          interval={0}
        />

        <ReferenceLine y={0} stroke="#000" />

        <Bar dataKey="values" stackId="a" fill="#f15a4f" radius={[10, 10, 0, 0]} barSize={10}>
          <LabelList dataKey="name" content={renderCustomizedLabel} />
        </Bar>
      </BarChart>
    </div>
  );
};

export default AlarmRecordsChart;
