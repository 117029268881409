import React from "react";
import { useTranslation } from "react-i18next";
const FraudDetectionDataset = () => {
  const { t } = useTranslation();
  const data = [
    ["7271","US","AUD","1","Weekday","MasterCard","Credit","1","0","39","10","No"],
    ["861","US","AUD","8","Weekend","Discover","Credit","1","0","57","5","No"],
    ["5391","US","CAD","12","Weekend","Visa","Credit","1","0","66","0","Yes"],
    ["5192","US","USD","5","Weekend","Amex","Credit","0","0","68","10","No"],
    ["5735","CA","CAD","3","Weekend","MasterCard","Credit","1","0","41","6","Yes"],
    ["6266","CA","CAD","13","Weekend","Visa","Credit","1","0","51","3","No"],
    ["467","Other","USD","10","Weekday","MasterCard","Credit","1","0","26","4","No"],
    ["4427","CA","CAD","1","Weekday","MasterCard","Credit","1","0","19","6","No"],
    ["5579","US","CAD","2","Weekday","Discover","Debit","1","0","62","2","Yes"],
    ["8323","AU","AUD","4","Weekend","Visa","Credit","0","0","42","1","No"],
    ["1686","AU","AUD","6","Weekday","Amex","Credit","1","1","29","10","No"],
    ["770","UK","CAD","10","Weekend","Visa","Debit","0","0","21","0","No"],
    ["6950","UK","USD","11","Weekend","Discover","Debit","1","1","68","7","Yes"],
    ["2434","CA","AUD","10","Weekend","Amex","Credit","1","0","57","7","No"],
    
     ]

  return (
    <div className="p-6 flex flex-col items-center bg-gray-50 ">
      <div className="border border-gray-400 rounded-lg p-6 w-full bg-white shadow-lg">
        {/* Header */}
        <h2 className="text-xl font-bold text-blue-600 text-center mb-6">{t("Veriseti")}</h2>
        
        {/* Table Section */}
        <div className="overflow-x-auto">
          <table className="text-sm w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-sky-700 text-sky-100">
                <th className="border border-gray-300 p-2">Transaction Amount</th>
                <th className="border border-gray-300 p-2">Transaction Country</th>
                <th className="border border-gray-300 p-2">Transaction Currency</th>
                <th className="border border-gray-300 p-2">Transaction Time</th>
                <th className="border border-gray-300 p-2">Transaction Day</th>
                <th className="border border-gray-300 p-2">Card Issuer</th>
                <th className="border border-gray-300 p-2">Card Type</th>
                <th className="border border-gray-300 p-2">Online Purchase</th>
                <th className="border border-gray-300 p-2">First Purchase</th>
                <th className="border border-gray-300 p-2">Customer Age</th>
                <th className="border border-gray-300 p-2">Account Age</th>
                <th className="border border-gray-300 p-2">Fraudulent</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index} className="text-gray-700">
                  {row.map((cell, cellIndex) => (
                    <td
                      key={cellIndex}
                      className={`text-sm text-center border border-gray-300 p-2 ${cellIndex===data.length-1?(cell==='Yes'? "text-green-600 font-bold":"text-red-600 font-bold"):""} `}
                    >
                      {cell}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        
      </div>

    </div>
  );
};

export default FraudDetectionDataset;
