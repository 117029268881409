import React from "react";
import { FaUserLarge } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
const RecommenderSystemsChart = () => {
  const { t } = useTranslation();
  return (
    <div className="w-[70%] p-6 flex flex-col items-center bg-gray-50">
      <div className="border border-gray-400 rounded-lg p-6 w-full bg-white shadow-lg">
        <div className="flex items-center justify-center">
          <div className="relative w-[1250px] h-[500px]  p-6">
            {/* Kullanıcı İkonu */}
            <FaUserLarge className="absolute left-[50px]  top-[160px] w-24 h-24 text-orange-600"></FaUserLarge>
            <div className="absolute left-[150px] top-[90px] text-slate-500 text-lg font-semibold text-center">
              {t("Anket_Verileri")}
            </div>
            <div className="absolute left-[150px] top-[190px] text-slate-500 text-lg font-semibold text-center">
               {t("Sitedeki_Hareketler")}
            </div>
            <div className="absolute left-[150px] top-[300px] text-slate-500 text-lg font-semibold text-center">
               {t("Sorgular")}
            </div>

            {/* İçerik Kutuları */}
            <div className="text-lg absolute left-[350px] top-[30px] w-[720px] h-[370px] border-4 border-blue-700 flex items-center justify-center text-blue-500 font-bold bg-white shadow-md rounded-md"></div>
            <div className="text-lg absolute left-[450px] top-[55px] w-64 h-14 border-2 border-green-500 flex items-center justify-center text-green-700 font-bold bg-white shadow-md rounded-md">
              {t("market_basket_analysis")}
            </div>
            <div className="text-lg absolute left-[450px] top-[220px] transform -translate-y-1/2 w-64 h-14 border-2 border-green-500 flex items-center justify-center text-green-700 font-bold bg-white shadow-md rounded-md">
               {t("clustering")}
            </div>
            <div className="text-lg absolute left-[450px] top-[330px] w-64 h-14 border-2 border-green-500 flex items-center justify-center text-green-700 font-bold bg-white shadow-md rounded-md">
              {t("customer_behavior_analysis")}
            </div>

            {/* Kullanıcı Veritabanı */}
            <div className="absolute left-[850px] top-[50px] w-48 h-12 border-2 border-yellow-500 rounded-full flex items-center justify-center text-yellow-700 font-bold bg-white shadow-md">
               {t("users_database")}
            </div>

            {/* Öneri Algoritması */}
            <div className="absolute left-[850px] top-[223px] transform -translate-y-1/2 w-48 h-48 border-2 border-red-600 flex items-center justify-center text-red-700 font-bold bg-white shadow-md rounded-md pl-2">
               {t("recommendation_algorithm")}
            </div>

            {/* Öneri Çıkışı */}
            <div className="absolute left-[1100px] top-[221px] transform -translate-y-1/2 w-60 h-14 border-4 border-[#e46b07] flex items-center justify-center text-[#e46b07] font-bold bg-white shadow-md rounded-md text-xl">
              {t("recommendation")}
            </div>

            {/* Oklar ve Bağlantılar (SVG ile) */}
            <svg className="absolute w-full h-full top-0 left-0">
              <defs>
                <marker
                  id="arrow"
                  markerWidth="10"
                  markerHeight="10"
                  refX="6"
                  refY="3"
                  orient="auto"
                  markerUnits="strokeWidth"
                >
                  <path d="M0,0 L0,6 L6,3 z" fill="blue" />
                </marker>
              </defs>
              <line
                x1="150"
                y1="160"
                x2="350"
                y2="80"
                stroke="blue"
                strokeWidth="2"
                markerEnd="url(#arrow)"
              />
              <line
                x1="150"
                y1="220"
                x2="350"
                y2="220"
                stroke="blue"
                strokeWidth="2"
                markerEnd="url(#arrow)"
              />
              <line
                x1="150"
                y1="280"
                x2="350"
                y2="360"
                stroke="blue"
                strokeWidth="2"
                markerEnd="url(#arrow)"
              />

              <line
                x1="705"
                y1="80"
                x2="850"
                y2="160"
                stroke="blue"
                strokeWidth="1"
                markerEnd="url(#arrow)"
              />
              <line
                x1="705"
                y1="220"
                x2="850"
                y2="220"
                stroke="blue"
                strokeWidth="1"
                markerEnd="url(#arrow)"
              />
              <line
                x1="705"
                y1="360"
                x2="850"
                y2="280"
                stroke="blue"
                strokeWidth="1"
                markerEnd="url(#arrow)"
              />

              <line
                x1="1040"
                y1="220"
                x2="1100"
                y2="220"
                stroke="blue"
                strokeWidth="1"
                markerEnd="url(#arrow)"
              />
              <line
                x1="940"
                y1="100"
                x2="940"
                y2="130"
                stroke="blue"
                strokeWidth="1"
                markerEnd="url(#arrow)"
              />

              <line
                x1="1220"
                y1="250"
                x2="1220"
                y2="450"
                stroke="#e46b07"
                strokeWidth="3"
              />
              <line
                x1="100"
                y1="450"
                x2="1220"
                y2="450"
                stroke="#e46b07"
                strokeWidth="3"
              />
              <line
                x1="100"
                y1="450"
                x2="100"
                y2="260"
                stroke="#e46b07"
                strokeWidth="3"
              />
              <polygon
                points="100 260, 93 270, 108 270"
                stroke="#e46b07"
                fill="#e46b07"
              />

              <line
                x1="350"
                y1="80"
                x2="450"
                y2="80"
                stroke="blue"
                strokeWidth="1"
                markerEnd="url(#arrow)"
              />
              <line
                x1="350"
                y1="220"
                x2="450"
                y2="220"
                stroke="blue"
                strokeWidth="1"
                markerEnd="url(#arrow)"
              />
              <line
                x1="350"
                y1="360"
                x2="450"
                y2="360"
                stroke="blue"
                strokeWidth="1"
                markerEnd="url(#arrow)"
              />

              <line
                x1="350"
                y1="80"
                x2="450"
                y2="220"
                stroke="blue"
                strokeWidth="1"
                markerEnd="url(#arrow)"
              />
              <line
                x1="350"
                y1="220"
                x2="450"
                y2="360"
                stroke="blue"
                strokeWidth="1"
                markerEnd="url(#arrow)"
              />
              <line
                x1="350"
                y1="360"
                x2="450"
                y2="80"
                stroke="blue"
                strokeWidth="1"
                markerEnd="url(#arrow)"
              />

              <line
                x1="350"
                y1="80"
                x2="450"
                y2="360"
                stroke="blue"
                strokeWidth="1"
                markerEnd="url(#arrow)"
              />
              <line
                x1="350"
                y1="220"
                x2="450"
                y2="80"
                stroke="blue"
                strokeWidth="1"
                markerEnd="url(#arrow)"
              />
              <line
                x1="350"
                y1="360"
                x2="450"
                y2="220"
                stroke="blue"
                strokeWidth="1"
                markerEnd="url(#arrow)"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecommenderSystemsChart;
