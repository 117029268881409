import React from "react";
import { useTranslation } from "react-i18next";
import PageHeaderSolutions from "../components/PageHeaderSolutions";
import { CaretRightFilled } from "@ant-design/icons";
// import predictive_maintenance from "../assets/images/applications/predictive_maintenance.jpg";
import IndustryBanner from "../appareas/IndustryBanner";
//import PredictiveMaintenanceChart from "./charts/PredictiveMaintenanceChart"

const PredictiveMaintenance = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeaderSolutions></PageHeaderSolutions>
      <section className="mt-24 w-full h-full relative bg-center bg-cover p-5 border-2 border-blue-900 rounded-lg bg-white">
        <div> <IndustryBanner></IndustryBanner> </div>
        <div className="items-center text-center">
          <h3 className="mt-2 text-blue-900 text-4xl font-semibold mb-2">
            {t("predictive_maintenance")}
          </h3>
        </div>

        <p className="text-2xl">
          <CaretRightFilled />
          {t("predictive_maintenance_description1")}
        </p>

        {/* <img src={predictive_maintenance} width={1200} height={1200} alt="predictive_maintenance" /> */}
      {/* <PredictiveMaintenanceChart></PredictiveMaintenanceChart> */}
      </section>
    </>
  );
};

export default PredictiveMaintenance;
