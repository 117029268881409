import React from "react";
import { useTranslation } from "react-i18next";
const MarketBasketAnalysisRules = () => {
  const { t } = useTranslation();
  const data = [
    [ "1", "[cips]  →  [ekmek]",        "1.00", "0.6", "0.67" ],
    [ "2", "[cips | kola]  →  [ekmek]", "1.00", "0.5", "0.55" ],
    [ "3", "[kola]  →  [ekmek]",        "1.00", "0.6", "0.67" ],
    [ "4", "[su]  →  [ekmek]",          "1.00", "0.6", "0.55" ],
    [ "5", "[kola]  →  [cips]",         "0.83", "0.5", "0.67" ],    
    [ "6", "[cips]  →  [kola]",         "0.83", "0.5", "0.67" ],
    [ "7", "[kola]  →  [cips | ekmek]", "0.83", "0.5", "0.67" ],
    [ "8", "[ekmek | kola]  →  [cips]", "0.83", "0.5", "0.67" ],
    [ "9", "[cips]  →  [ekmek | kola]", "0.83", "0.5", "0.67" ],
    [ "10", "[cips | ekmek]  →  [kola]","0.83", "0.5", "0.67" ],   
  ]

  return (
    <div className="w-[50%] p-6 flex flex-col items-center bg-gray-50">
      <div className="border border-gray-400 rounded-lg p-6 w-full bg-white shadow-lg">
        {/* Header */}
        <h2 className="text-xl font-bold text-blue-600 text-center">{t("Kurallar")}</h2>
        
        {/* Table Section */}
        <div className="overflow-x-auto">
          <table className="text-lg w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-sky-700 text-sky-100">
                <th className="border border-gray-300 p-2">{t("No.")}</th>
                <th className="border border-gray-300 p-2">{t("Kural")}</th>
                <th className="border border-gray-300 p-2">{t("Guven")}</th>
                <th className="border border-gray-300 p-2">{t("Sıklık_Oranı")}</th>
                <th className="border border-gray-300 p-2">{t("Ilgi")}</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index} className="text-gray-700">
                  {row.map((cell, cellIndex) => (
                    <td
                      key={cellIndex}
                      className={`text-lg text-center border border-gray-300 p-2`}
                    >
                      {cell}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        
      </div>

    </div>
  );
};

export default MarketBasketAnalysisRules;
