import React from "react";
import { FaUsers } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { VscTriangleRight } from "react-icons/vsc";
import { FaMicrochip } from "react-icons/fa6";

const LoyaltyProgramDevelopmentChart = () => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col items-center p-10 bg-gray-100 min-h-screen">
            <div className="grid grid-cols-[2fr_1fr_2fr] gap-8 w-full max-w-6xl items-center">
                {/* Sol Taraf: Müşteri Segmentasyonu */}
                <div className="bg-orange-50  rounded-lg shadow-md border-4 border-orange-200 w-[300px]">
                    <h2 className="text-center font-bold text-lg p-2"> {t("Customer_Segmentation")}</h2>
                    <div className="text-sm text-gray-700 space-y-4">
                        <div className="w-[300px] border-2 border-orange-200"></div>
                        <div className="pl-2">
                            <p className="font-semibold">{t("Demografik")}</p>
                            <ul className="ml-4 list-disc">
                                <li>{t("Yaş")}</li>
                                <li>{t("Cinsiyet")}</li>
                                <li>{t("Gelir_Düzeyi")}</li>
                                <li>{t("Eğitim_Düzeyi")}</li>
                                <li>{t("Meslek")}</li>
                            </ul>
                        </div>
                        <div className="w-[300px] border-2 border-orange-200"></div>
                        <div  className="pl-2">
                            <p className="font-semibold">{t("Coğrafi")}</p>
                            <ul className="ml-4 list-disc">
                                <li>{t("Yaşadığı_Yer")}</li>
                            </ul>
                        </div>
                        <div className="w-[300px] border-2 border-orange-200"></div>
                        <div className="pl-2">
                            <p className="font-semibold">Psikografik{t("")}</p>
                            <ul className="ml-4 list-disc">
                                <li>{t("Yaşam_Stili")}</li>
                                <li>{t("Değerleri")}</li>
                                <li>{t("Kişiliği")}</li>
                                <li>{t("İlgileri")}</li>
                            </ul>
                        </div>
                        <div className="w-[300px] border-2 border-orange-200"></div>
                        <div className="pl-2 pb-2">
                            <p className="font-semibold">{t("Davranışsal")}</p>
                            <ul className="ml-4 list-disc">
                                <li>{t("Satın_Alma_Davranışı")}</li>
                                <li>{t("Kullanım_Sıklığı")}</li>
                                <li>{t("Marka_Sadakati")}</li>
                                <li>{t("Yeni_Ürünleri_Benimseme")}</li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* Orta Taraf: İkon ve Bağlantılar */}
                <div className="flex items-center">
                    <div className="bg-purple-800 rounded-lg flex items-center justify-center">
                        <FaUsers className="text-white text-7xl p-2" />
                    </div>
                    <div className="text-7xl text-orange-300">
                        <VscTriangleRight></VscTriangleRight>
                    </div>

                    <div className="ml-2 w-full flex flex-col">
                        <div className="bg-sky-100 border-2 border-sky-300   rounded-lg shadow-md text-center p-2 m-2">{t("Demografik")}</div>
                        <div className="bg-sky-100 border-2 border-sky-300  rounded-lg shadow-md text-center p-2 m-2">{t("Coğrafi")}</div>
                        <div className="bg-sky-100 border-2 border-sky-300  rounded-lg shadow-md text-center p-2 m-2">{t("Psikografik")}</div>
                        <div className="bg-sky-100 border-2 border-sky-300  rounded-lg shadow-md text-center p-2 m-2">{t("Davranışsal")}</div>
                    </div>

                    <div className="text-7xl text-orange-300">
                        <VscTriangleRight></VscTriangleRight>
                    </div>

                    <div className="bg-purple-800 rounded-lg flex items-center justify-center">
                        <FaMicrochip className="text-white text-7xl p-2" />
                    </div>
                </div>

                {/* Sağ Taraf: Sonuçlar */}
                <div className="text-sm text-gray-700 space-y-4 w-full">
                        
                        <div className="flex justify-between items-center">
                            <p className="font-semibold bg-sky-200   rounded-md w-[200px] h-[60px] flex items-center justify-center border-2 border-sky-300">{t("Değiştirmeye_Eğilimli")}</p>
                            <div className="text-4xl text-orange-300">
                                <VscTriangleRight></VscTriangleRight>
                            </div>
                            <p className="bg-green-800 text-white  rounded-md w-[240px] h-[60px] flex items-center justify-center  ">{t("Yoğun_Reklam_Kampanyası")}</p>
                        </div>

                        <div className="flex justify-between items-center">
                            <p className="font-semibold  bg-sky-200  rounded-md w-[200px] h-[60px] flex items-center justify-center border-2 border-sky-300 ">{t("İade_Etmeye_Eğilimli")}</p>
                            <div className="text-4xl text-orange-300">
                                <VscTriangleRight></VscTriangleRight>
                            </div>
                            <p className="bg-green-800 text-white  rounded-md w-[240px] h-[60px] flex items-center justify-center  ">{t("Etkinlik_Tabanlı_Tetikleyici")}</p>
                        </div>

                        <div className="flex justify-between items-center">
                            <p className="font-semibold bg-sky-200  rounded-md w-[200px] h-[60px] flex items-center justify-center border-2 border-sky-300 ">{t("Tekrar_Satın_Almaya_Eğilimli")}</p>
                            <div className="text-4xl text-orange-300">
                                <VscTriangleRight></VscTriangleRight>
                            </div>
                            <p className="bg-green-800 text-white  rounded-md w-[240px] h-[60px] flex items-center justify-center  ">{t("Çeşitli_Ürün_Önerileri")}</p>
                        </div>

                        <div className="flex justify-between items-center">
                            <p className="font-semibold  bg-sky-200  rounded-md w-[200px] h-[60px] flex items-center justify-center border-2 border-sky-300 ">{t("Tavsiye_Etmeye_Eğilimli")}</p>
                            <div className="text-4xl text-orange-300">
                                <VscTriangleRight></VscTriangleRight>
                            </div>
                            <p className="bg-green-800 text-white  rounded-md w-[240px] h-[60px] flex items-center justify-center  ">{t("Sadakat_Programları")}</p>
                        </div>
                    </div>
            </div>
        </div>
    );
};

export default LoyaltyProgramDevelopmentChart;
