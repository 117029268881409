import BackgroudImage from "../assets/images/bg/choose-us-bg3.jpg";
import AppCardEcommerce from "../appareas/AppCardEcommerce";
import AppCardIndustry from "../appareas/AppCardIndustry";
import AppCardTelecommunication from "../appareas/AppCardTelecommunication";
import AppCardSecurity from "../appareas/AppCardSecurity";
import AppCardHealthcare from "../appareas/AppCardHealthcare";
import AppCardOther from "../appareas/AppCardOther";
import { useTranslation } from "react-i18next";

const ApplicationAreas = ({ title, description, link }) => {
  const { t } = useTranslation();
  return (
    <div
      className="flex-col "
      style={{ backgroundImage: `url(${BackgroudImage})` }}
    >
      <div className="text-center text-3xl m-4 font-bold text-orange-800">{t("APPLICATIONS")}</div>
      <div className="flex m-4 gap-4">
        <AppCardEcommerce />
        <AppCardIndustry />
        <AppCardTelecommunication />
      </div>

      <div className="flex m-4 gap-4">
        <AppCardSecurity/>
        <AppCardHealthcare/>
        <AppCardOther/>
      </div>

    <div className="mb-16"></div>


    </div>
  );
};
export default ApplicationAreas;
