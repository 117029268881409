import BackgroudImage from "../assets/images/bg/choose-us-bg3.jpg";
import AppCard from "./AppCard"
import TelecommunicationBanner from "./TelecommunicationBanner";
import { useTranslation } from 'react-i18next';

const TelecommunicationApps = () =>  {
  const { t } = useTranslation();
  return (

    <div className="flex-col" style={{ backgroundImage: `url(${BackgroudImage})` }}>

      <div> <TelecommunicationBanner></TelecommunicationBanner> </div>
      
      <div className="flex p-8 gap-8">
            <AppCard title={t("alarm_analysis")} description={t("production_time_prediction_description")} link="AlarmAnalysisTelco"></AppCard>
            <AppCard title={t("predictive_maintenance")} description={t("predictive_maintenance_description")} link="PredictiveMaintenanceTelco"></AppCard>
            <AppCard title={t("root_cause_analysis")} description={t("root_cause_analysis_description")} link="RootCauseAnalysisTelco"></AppCard>
            <AppCard title={t("anomaly_detection")} description={t("anomaly_detection_description")} link="AnomalyDetectionTelco"></AppCard>
      </div>

    </div>
    
  );
}
export default TelecommunicationApps;
