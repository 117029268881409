import React from "react";
import { Link as Link2 } from "react-router-dom";
import BackgroudImage from "../assets/images/bg/1.jpg";
import { useTranslation } from 'react-i18next';

export default function HomePageBanner2() {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <section
          style={{ backgroundImage: `url(${BackgroudImage})` }}
          className="py-16 lg:py-36 w-full table relative bg-center bg-cover pl-5"
          id="home"
        >
          <div className="absolute inset-0 bg-black opacity-80"></div>
          <div className="container relative">

            <div className="grid grid-cols-1 mt-12 flex justify-between">
              
              <div className="">
                    <h4 className="text-white lg:text-4xl text-3xl font-medium mb-7 flex">
                      <i>{t("Providing_brilliant_insights_for_your")}</i>
                      <div className="ml-3 border-2 border-gray-600"></div> 
                      <div className="ml-3 text-orange-400 text-2xl">
                        <p>{t("business")}</p>
                        <p>{t("startups")}</p>
                        <p>{t("digital agency")}</p>
                        <p>{t("marketing")}</p>
                        <p>{t("planning")}</p>
                      </div>
                      <div className="ml-3 mr-3 border-2 border-gray-600"></div>
                      <i>{t("in_various_areas_like")}</i>
                      <div className="ml-3 border-2 border-gray-600"></div> 
                      <div className="ml-3 text-orange-400 text-2xl">
                        <p>{t("e_commerce")}</p>
                        <p>{t("industry")}</p>
                        <p>{t("telecommunication")}</p>
                        <p>{t("security")}</p>
                        <p>{t("healthcare")}</p>
                      </div>
                    </h4>
                    <p className="text-white opacity-50 mb-0 max-w-2xl text-lg">
                    {t("launch")}
                    </p>
                    <div className="relative mt-10">
                      <Link2
                        to="https://datanom.us"
                        className="p-2 btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md"
                      >
                        {t("get_started")}
                      </Link2>
                    </div>
              </div>

            </div>

          </div>
        </section>
      </div>
    </>
  );
}
