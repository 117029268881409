import React from "react";
import { useTranslation } from "react-i18next";
import PageHeaderSolutions from "../components/PageHeaderSolutions";
import { CaretRightFilled } from "@ant-design/icons";
//import market_basket_analysis from "../assets/images/applications/market_basket_analysis.jpg";
import EcommerceBanner from "../appareas/EcommerceBanner";
import MarketBasketAnalysisDataset from "./charts/MarketBasketAnalysisDataset"
import MarketBasketAnalysisPatterns from "./charts/MarketBasketAnalysisPatterns"
import MarketBasketAnalysisRules from "./charts/MarketBasketAnalysisRules"
import MarketBasketAnalysisRulesGraph from "./charts/MarketBasketAnalysisRulesGraph"

const MarketBasketAnalysis = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeaderSolutions></PageHeaderSolutions>
      <section className="mt-24 w-full h-full relative bg-center bg-cover p-5 border-2 border-blue-900 rounded-lg bg-white">
        <div> <EcommerceBanner></EcommerceBanner> </div>
        <div className="items-center text-center">
          <h3 className="mt-2 text-blue-900 text-4xl font-semibold mb-2">
            {t("market_basket_analysis")}
          </h3>
        </div>

        <p className="text-2xl">
          <CaretRightFilled />
          {t("market_basket_analysis_description1")}
        </p>
        <p className="text-2xl">
          <CaretRightFilled />
          {t("market_basket_analysis_description2")}
        </p>

        {/* <img src={market_basket_analysis} width={1200} height={1200} alt="market_basket_analysis" /> */}
        <div className="flex">
        <MarketBasketAnalysisDataset></MarketBasketAnalysisDataset>
        <MarketBasketAnalysisPatterns></MarketBasketAnalysisPatterns>
        </div>

        <div className="flex">
        <MarketBasketAnalysisRules></MarketBasketAnalysisRules>
        <MarketBasketAnalysisRulesGraph></MarketBasketAnalysisRulesGraph>
        </div>
      </section>
    </>
  );
};

export default MarketBasketAnalysis;
