import React from "react";
import { useTranslation } from "react-i18next";
import PageHeaderSolutions from "../components/PageHeaderSolutions";
import { CaretRightFilled } from "@ant-design/icons";
import energy_consumption_forecasting from "../assets/images/applications/market_basket_analysis.jpg";
import IndustryBanner from "../appareas/IndustryBanner";

const EnergyConsumptionForecasting = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeaderSolutions></PageHeaderSolutions>
      <section className="mt-24 w-full h-full relative bg-center bg-cover p-5 border-2 border-blue-900 rounded-lg bg-white">
        <div> <IndustryBanner></IndustryBanner> </div>
        <div className="items-center text-center">
          <h3 className="mt-2 text-blue-900 text-4xl font-semibold mb-2">
            {t("energy_consumption_forecasting")}
          </h3>
        </div>

        <p className="text-2xl">
          <CaretRightFilled />
          {t("energy_consumption_forecasting_description1")}
        </p>
        <p className="text-2xl">
          <CaretRightFilled />
          {t("energy_consumption_forecasting_description2")}
        </p>

        {/* <img src={energy_consumption_forecasting} width={1200} height={1200} alt="energy_consumption_forecasting" /> */}
      </section>
    </>
  );
};

export default EnergyConsumptionForecasting;
