import React from "react";
import { useTranslation } from "react-i18next";
import { useEffect, useRef } from "react";
import { Network } from 'vis-network';

const CustomerBehaviorAnalysisRulesGraph = () => {
    const { t } = useTranslation();
    const container = useRef(null);

const fsmnodesedges = [ 
  ["[kars.]","[sat.al.]","[sep.at.]","[sep.at.] < [sat.al.]","[kars.] < [sep.at.]"],
  [  {id: '[kars.]-->[sat.al.]', source: '[kars.]', target: '[sat.al.]', label: '0.833'},
    {id: '[kars.]-->[sep.at.]', source: '[kars.]', target: '[sep.at.]', label: '0.833'},
    {id: '[kars.]-->[sep.at.] < [sat.al.]', source: '[kars.]', target: '[sep.at.] < [sat.al.]', label: '0.833'},
    {id: '[kars.] < [sep.at.]-->[sat.al.]', source: '[kars.] < [sep.at.]', target: '[sat.al.]', label: '1.0'},
    {id: '[sep.at.]-->[sat.al.]', source: '[sep.at.]', target: '[sat.al.]', label: '1.0'},]
  ]

    const nodes = []
    const edges = []
    if (fsmnodesedges) {
    const nodesfrompython = fsmnodesedges[0];
    const edgesfrompython = fsmnodesedges[1];
    if (nodesfrompython) {
        for (let ii = 0; ii < nodesfrompython.length; ii++) {
            const tmp = {
              id: nodesfrompython[ii],
              label: nodesfrompython[ii], 
            }
            nodes.push(tmp)
          }
          for (let jj = 0; jj < edgesfrompython.length; jj++) {
            const tmp = {
              label: edgesfrompython[jj]["label"], 
              from: edgesfrompython[jj]["source"],
              to: edgesfrompython[jj]["target"],
              font: {
                color: "darkgreen",
                background: "white",
                size: "14",
                align: "horizontal",
                vadjust: 1
              },
            }
            edges.push(tmp)
          }
    }
  }



  const options = {
    physics: {
      enabled: false,
      stabilization: true
    },

    layout: {

      improvedLayout: true,
      hierarchical: {
        enabled: false,
        blockShifting: true,
        edgeMinimization: true,
        parentCentralization: false,
        shakeTowards: "leaves", // roots, leaves

      },
      interaction: { dragNodes: true }
    },
    interaction: {
      selectable: true,
      selectConnectedEdges: true
    },
    edges: {
      shadow: {
        enabled: false,
        color: "rgba(0,0,0,.5)",
        size: 1,
        x: 1,
        y: 1,
      },
      smooth: {
        enabled: true,
        type: "diagonalCross",
        roundness: 0.5,
        forceDirection: "none"
      },
      arrows: {
        to: {
          enabled: true,
          scaleFactor: 1.0,
          type: "arrow"
        },

      },
      color: {color: "rgb(0,0,150)", highlight: "rgb(255,127,127)"},
      width: 1.5,
    },
    nodes: {
      shape: "circle",
      size: 16,
      font: {size: 12, color: "rgb(0,0,0)",},
      borderWidth: 3,
      color: { background: "rgb(255,255,150)", border: "rgb(0,0,150)", highlight: "rgb(255,127,127)" },
    },

  };

  useEffect(() => {
    const network =
      container.current &&
      new Network(container.current, { nodes, edges }, options);
      console.log(network)
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container, nodes, edges]);

  return (
    <div className="w-[50%] p-6 flex flex-col items-center bg-gray-50">
      <div className="border border-gray-400 rounded-lg p-6 w-full bg-white shadow-lg">
        {/* Header */}
        <h2 className="text-xl font-bold text-blue-600 text-center">{t("Kural_Grafigi")}</h2>
        
        <div ref={container} className='w-full h-[500px] border-2' />
        
      </div>

    </div>
  );
};

export default CustomerBehaviorAnalysisRulesGraph;
