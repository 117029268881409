import React from "react";
import { useTranslation } from "react-i18next";
import PageHeaderSolutions from "../components/PageHeaderSolutions";
import { CaretRightFilled } from "@ant-design/icons";
import EcommerceBanner from "../appareas/EcommerceBanner";
import RecommenderSystemsChart from "./charts/RecommenderSystemsChart"

const RecommenderSystems = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeaderSolutions></PageHeaderSolutions>
      <section className="mt-24 w-full h-full relative bg-center bg-cover p-5 border-2 border-blue-900 rounded-lg bg-white">
        <div> <EcommerceBanner></EcommerceBanner> </div>
        <div className="items-center text-center">
          <h3 className="mt-2 text-blue-900 text-4xl font-semibold mb-2">
            {t("recommender_systems")}
          </h3>
        </div>

        <p className="text-2xl">
          <CaretRightFilled />
          {t("recommender_systems_description1")}
        </p>
        <p className="text-2xl">
          <CaretRightFilled />
          {t("recommender_systems_description2")}
        </p>


<RecommenderSystemsChart></RecommenderSystemsChart>


      </section>
    </>
  );
};

export default RecommenderSystems;
