import React from "react";
import { useTranslation } from "react-i18next";
const RootCauseAnalysisDataset = () => {
  const { t } = useTranslation();
  const data = localStorage.getItem("i18nextLng")==='tr'?
  [
    ["K1, Y1, S1", "Y2, M1, S2, I1, S1", "I2, S3", "S4, K1", "M2, C1", "ARIZA"],
    ["Y2, M2", "I1, T1", "M1, S4, Y2", "U1, S6", "D1, M2, I1, I2", "ARIZA"],
    ["F1, K1, C1", "K1, I2", "K1, I2", "D1, S3", "S4, D2", "ARIZA"],
    ["C1, K2", "I2, K1, S5, K2", "S2, I1, S3", "S1, S3, I2", "T1, M1, Y1", "ARIZA"],
    ["I2, M1", "K1, Y1", "S1, F1, S3", "S3, S6, F1", "S6, Y1, S4", "ARIZA"],
    ["K2, S1, D1", "Y1, Y2, S2", "I2, F1, S3", "U1, S6", "K2", "ARIZA"],
    ["S6, I2", "S7, C1, K2", "K3, D1", "Y1, D1", "M2, F1, M1", "ARIZA"],
    ["Y1, Y1, Y2", "I2, Y1, I1, S1", "I2, Y1, I1, S1", "Y1, Y1", "S7", "ARIZA"],
  ]:
  [
    ["K1, Y1, S1", "Y2, M1, S2, I1, S1", "I2, S3", "S4, K1", "M2, C1", "FAILURE"],
    ["Y2, M2", "I1, T1", "M1, S4, Y2", "U1, S6", "D1, M2, I1, I2", "FAILURE"],
    ["F1, K1, C1", "K1, I2", "K1, I2", "D1, S3", "S4, D2", "FAILURE"],
    ["C1, K2", "I2, K1, S5, K2", "S2, I1, S3", "S1, S3, I2", "T1, M1, Y1", "FAILURE"],
    ["I2, M1", "K1, Y1", "S1, F1, S3", "S3, S6, F1", "S6, Y1, S4", "FAILURE"],
    ["K2, S1, D1", "Y1, Y2, S2", "I2, F1, S3", "U1, S6", "K2", "FAILURE"],
    ["S6, I2", "S7, C1, K2", "K3, D1", "Y1, D1", "M2, F1, M1", "FAILURE"],
    ["Y1, Y1, Y2", "I2, Y1, I1, S1", "I2, Y1, I1, S1", "Y1, Y1", "S7", "FAILURE"],
  ]

  return (
    <div className="w-[50%] p-6 flex flex-col items-center bg-gray-50 ">
      <div className="border border-gray-400 rounded-lg p-6 w-full max-w-6xl bg-white shadow-lg">
        {/* Header */}
        <h2 className="text-xl font-bold text-blue-600 text-center mb-6">{t("Ariza_Oncesi_Kayitlar")}</h2>
        
        {/* Table Section */}
        <div className="overflow-x-auto mb-6">
          <table className="w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-sky-700 text-sky-100">
                <th className="border border-gray-300 p-2">T1</th>
                <th className="border border-gray-300 p-2">T2</th>
                <th className="border border-gray-300 p-2">T3</th>
                <th className="border border-gray-300 p-2">T4</th>
                <th className="border border-gray-300 p-2">T5</th>
                <th className="border border-gray-300 p-2">T6</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index} className="text-gray-700">
                  {row.map((cell, cellIndex) => (
                    <td
                      key={cellIndex}
                      className={`text-xl text-center border border-gray-300 p-2 ${cell === "ARIZA" ? "text-red-600 font-bold" : (cell === "FAILURE" ? "text-red-600 font-bold":"")}`}
                    >
                      {cell}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        

        {/* <div className="border border-blue-400 rounded-lg p-4 bg-blue-50">
          <h3 className="text-blue-600 font-bold text-center">Kurallar</h3>
          <div className="flex justify-center mt-4">
            <svg width="400" height="200" viewBox="0 0 400 200" xmlns="http://www.w3.org/2000/svg">

              <circle cx="80" cy="100" r="30" fill="yellow" stroke="blue" strokeWidth="2" />
              <circle cx="180" cy="60" r="20" fill="yellow" stroke="blue" strokeWidth="2" />
              <circle cx="180" cy="140" r="30" fill="yellow" stroke="blue" strokeWidth="2" />
              <circle cx="280" cy="60" r="30" fill="yellow" stroke="blue" strokeWidth="2" />
              <circle cx="280" cy="140" r="30" fill="yellow" stroke="blue" strokeWidth="2" />
              <circle cx="350" cy="100" r="25" fill="red" stroke="blue" strokeWidth="2" />
              

              <line x1="110" y1="100" x2="160" y2="65" stroke="blue" strokeWidth="2" markerEnd="url(#arrow)" />
              <line x1="110" y1="100" x2="160" y2="135" stroke="blue" strokeWidth="2" markerEnd="url(#arrow)" />
              <line x1="200" y1="60" x2="260" y2="60" stroke="blue" strokeWidth="2" markerEnd="url(#arrow)" />
              <line x1="200" y1="140" x2="260" y2="140" stroke="blue" strokeWidth="2" markerEnd="url(#arrow)" />
              <line x1="300" y1="140" x2="340" y2="110" stroke="blue" strokeWidth="2" markerEnd="url(#arrow)" />
              

              <text x="70" y="105" fontSize="14" fill="black">[K1]</text>
              <text x="170" y="65" fontSize="14" fill="black">[S3]</text>
              <text x="170" y="145" fontSize="14" fill="black">[S2]</text>
              <text x="270" y="65" fontSize="14" fill="black">[K1] &lt; [S2]</text>
              <text x="270" y="145" fontSize="14" fill="black">[S3]</text>
              <text x="340" y="105" fontSize="14" fill="white">ARIZA</text>
            </svg>
          </div>
        </div> */}
        
      </div>

    </div>
  );
};

export default RootCauseAnalysisDataset;
