import React from "react";
import { useTranslation } from "react-i18next";
const CustomerBehaviorAnalysisPatterns = () => {
  const { t } = useTranslation();
  const data = [
    [ "1", "[kars.]≺[sep.at.]≺[sat.al.]", "3", "5", "0.5" ],
    [ "2", "[iade.et.]≺[fav.ekl.]", "2", "5", "0.5" ],
    [ "3", "[incele.]≺[fav.ekl.]", "2", "5", "0.5" ],
    [ "4", "[incele.]≺[yor.oku.]", "2", "5", "0.5" ],
    [ "5", "[kars.]≺[sat.al.]", "2", "5", "0.5" ],    
    [ "6", "[kars.]≺[sep.at.]", "2", "5", "0.5" ],
    [ "7", "[sep.at.]≺[sat.al.]", "2", "5", "0.5" ],    
    [ "8", "[yor.oku.]≺[fav.ekl.]", "2", "5", "0.5" ], 
    ]

  return (
    <div className="p-6 flex flex-col items-center bg-gray-50">
      <div className="border border-gray-400 rounded-lg p-6 w-full bg-white shadow-lg">
        {/* Header */}
        <h2 className="text-xl font-bold text-blue-600 text-center"> {t("Frequent_Sequences")}</h2>
        
        {/* Table Section */}
        <div className="overflow-x-auto">
          <table className="text-lg w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-sky-700 text-sky-100">
                <th className="border border-gray-300 p-2">{t("No.")}</th>
                <th className="border border-gray-300 p-2">{t("Sekans")}</th>
                <th className="border border-gray-300 p-2">{t("Uzunluk")}</th>
                <th className="border border-gray-300 p-2">{t("Sıklık_Sayısı")}</th>
                <th className="border border-gray-300 p-2">{t("Sıklık_Oranı")}</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index} className="text-gray-700">
                  {row.map((cell, cellIndex) => (
                    <td
                      key={cellIndex}
                      className={`text-lg text-center border border-gray-300 p-2`}
                    >
                      {cell}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        
      </div>

    </div>
  );
};

export default CustomerBehaviorAnalysisPatterns;
