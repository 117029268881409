import React from "react";
import { useTranslation } from "react-i18next";
import PageHeaderSolutions from "../components/PageHeaderSolutions";
import { CaretRightFilled } from "@ant-design/icons";
//import loyalty_program_development from "../assets/images/applications/loyalty_program_development.jpg";
import EcommerceBanner from "../appareas/EcommerceBanner";
import LoyaltyProgramDevelopmentChart from "./charts/LoyaltyProgramDevelopmentChart"

const LoyaltyProgramDevelopment = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeaderSolutions></PageHeaderSolutions>
      <section className="mt-24 w-full h-full relative bg-center bg-cover p-5 border-2 border-blue-900 rounded-lg bg-white">
        <div> <EcommerceBanner></EcommerceBanner> </div>
        <div className="items-center text-center">
          <h3 className="mt-2 text-blue-900 text-4xl font-semibold mb-2">
            {t("loyalty_program_development")}
          </h3>
        </div>

        <p className="text-2xl">
          <CaretRightFilled />
          {t("loyalty_program_development_description1")}
        </p>
        <p className="text-2xl">
          <CaretRightFilled />
          {t("loyalty_program_development_description2")}
        </p>

        {/* <img src={loyalty_program_development} width={1200} height={1200} alt="loyalty_program_development" /> */}

        <LoyaltyProgramDevelopmentChart></LoyaltyProgramDevelopmentChart>


      </section>
    </>
  );
};

export default LoyaltyProgramDevelopment;
