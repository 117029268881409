import React from "react";
import { useTranslation } from "react-i18next";
const CustomerBehaviorAnalysisDataset = () => {
  const { t } = useTranslation();
  const data = [
    ["M0001", "karş., yor.oku., sep.çık.", "yor.yaz., mağ.tak., sep.at., iade.et., sep.çık.", "incele., sat.al.", "sip.bak., karş.", "mes.gön., chatbot" ],
    ["M0002", "yor.yaz., mes.gön.", "iade.et., tekr.al.", "mağ.tak., sip.bak., yor.yaz.", "iade.et., fav.ekl.", "değerl., mes.gön., iade.et., incele." ],
    ["M0003", "fav.ekl., karş., chatbot", "karş., incele.", "karş., chatbot, sep.at.", "değerl., sat.al.", "sip.bak., duy.terc." ],
    ["M0004", "chatbot, kup.kull.", "incele., karş., sip.ipt., kup.kull.", "sep.at., iade.et., chatbot", "sep.çık., sat.al., incele.", "tekr.al., mağ.tak., yor.oku." ],
    ["M0005", "incele., mağ.tak.", "karş., yor.oku.", "sep.çık., fav.ekl., yor.oku., sep.at.", "sat.al., sor.sor., fav.ekl.", "sor.sor., yor.oku., sip.bak." ],
    ["M0006", "kup.kull., sep.çık., değerl.", "fav.ekl., incele., iade.et., değerl.", "adr.günc., yor.oku.", "tekr.al., fav.ekl.", "kup.kull." ],
    ["M0007", "yor.yaz., karş.", "mağ.tak., yor.yaz., sep.at.", "karş., fav.ekl., sat.al., yor.oku.", "upgrade, sor.sor.", "iade.et., incele., sep.çık., yor.yaz." ],
    ["M0008", "sor.sor., incele.", "incele., yor.oku., iade.et., mağ.tak.", "karş., değerl.", "fav.ekl., yor.oku., değerl.", "mes.gön., fav.ekl., mağ.tak." ],
    ["M0009", "yor.oku., sep.çık., yor.yaz.", "iade.et., incele., sep.çık., yor.yaz.", "kre.kull., öde.günc.", "fav.ekl., sep.çık.", "sip.ipt." ],
    ["M0010", "iade.et., kup.kull.", "sip.ipt., chatbot, kup.kull.", "incele., yor.oku., iade.et., sep.çık.", "yor.yaz., yor.oku., iade.et.", "yor.oku., fav.ekl., mağ.tak." ],
  ]

  return (
    <div className="p-6 flex flex-col items-center bg-gray-50">
      <div className="border border-gray-400 rounded-lg p-6 w-full bg-white shadow-lg">
        {/* Header */}
        <h2 className="text-xl font-bold text-blue-600 text-center">{t("Veriseti")}</h2>
        
        {/* Table Section */}
        <div className="overflow-x-auto">
          <table className="text-lg w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-sky-700 text-sky-100">
              <th className="border border-gray-300 p-2"> {t("Musteri_ID")}</th>
                <th className="border border-gray-300 p-2"> {t("GUN")} 1</th>
                <th className="border border-gray-300 p-2"> {t("GUN")} 2</th>
                <th className="border border-gray-300 p-2"> {t("GUN")} 3</th>
                <th className="border border-gray-300 p-2"> {t("GUN")} 4</th>
                <th className="border border-gray-300 p-2"> {t("GUN")} 5</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index} className="text-gray-700">
                  {row.map((cell, cellIndex) => (
                    <td
                      key={cellIndex}
                      className={`text-lg text-center border border-gray-300 p-2`}
                    >
                      {cell}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        
      </div>

    </div>
  );
};

export default CustomerBehaviorAnalysisDataset;
