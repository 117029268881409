import React from "react";
import { useTranslation } from "react-i18next";
import PageHeaderSolutions from "../components/PageHeaderSolutions";
import { CaretRightFilled } from "@ant-design/icons";
// import crime_investigation from "../assets/images/applications/market_basket_analysis.jpg";
import SecurityBanner from "../appareas/SecurityBanner";

const CrimeInvestigation = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeaderSolutions></PageHeaderSolutions>
      <section className="mt-24 w-full h-full relative bg-center bg-cover p-5 border-2 border-blue-900 rounded-lg bg-white">
        <div> <SecurityBanner></SecurityBanner> </div>
        <div className="items-center text-center">
          <h3 className="mt-2 text-blue-900 text-4xl font-semibold mb-2">
            {t("crime_investigation")}
          </h3>
        </div>

        <p className="text-2xl">
          <CaretRightFilled />
          {t("crime_investigation_description1")}
        </p>


        {/* <img src={crime_investigation} width={1200} height={1200} alt="crime_investigation" /> */}
      </section>
    </>
  );
};

export default CrimeInvestigation;
