import React from "react";
import BackgroudImage from "../assets/images/bg/3.jpg";
import { useTranslation } from "react-i18next";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

export default function TelecommunicationBanner() {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <section
          style={{ backgroundImage: `url(${BackgroudImage})` }}
          className="py-16 lg:py-16 w-full table relative bg-center bg-cover pl-5"
          id="home"
        >
          <div className="absolute inset-0 bg-black opacity-80"></div>
          <div className="container relative">
            <div className="grid grid-cols-1 mt-12 flex justify-between">
              <div className="">
                <h4 className="text-white lg:text-4xl text-3xl font-medium mb-7 flex">
                  <i>
                    {t("providing_brilliant")}{" "}
                    <ArrowRightOutlined
                      style={{ color: "#8080ff", fontStyle: "bold" }}
                    />{" "}
                    <b className="text-[#8080ff] pr-2">{t("telecommunication")}</b>{" "}
                  </i>
                  <div className="ml-3 border"></div>
                  <div className="flex flex-col ml-3 text-orange-400 text-2xl">
                  <Link to="/solutions/AlarmAnalysisTelco" className="p-2 hover:bg-slate-600 rounded-lg duration-300"> {t("alarm_analysis")} </Link>
                    <Link to="/solutions/PredictiveMaintenanceTelco" className="p-2 hover:bg-slate-600 rounded-lg duration-300"> {t("predictive_maintenance")} </Link>
                    <Link to="/solutions/RootCauseAnalysisTelco" className="p-2 hover:bg-slate-600 rounded-lg duration-300"> {t("root_cause_analysis")}  </Link>
                    <Link to="/solutions/AnomalyDetectionTelco" className="p-2 hover:bg-slate-600 rounded-lg duration-300"> {t("anomaly_detection")} </Link>
                  </div>
                </h4>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
