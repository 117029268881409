import {
    ResponsiveContainer,
    XAxis,
    YAxis,
    LineChart,
    Line,
    CartesianGrid,
} from "recharts";


const SalesForecastingChart = () => {
    const color = "blue"
    const xticksTSF = [
        "1.04.2021 00:00",
        "1.04.2021 01:00",
        "1.04.2021 02:00",
        "1.04.2021 03:00",
        "1.04.2021 04:00",
        "1.04.2021 05:00",
        "1.04.2021 06:00",
        "1.04.2021 07:00",
        "1.04.2021 08:00",
        "1.04.2021 09:00",
        "1.04.2021 10:00",
        "1.04.2021 11:00",
        "1.04.2021 12:00",
        "1.04.2021 13:00",
        "1.04.2021 14:00",
        "1.04.2021 15:00",
        "1.04.2021 16:00",
        "1.04.2021 17:00",
        "1.04.2021 18:00",
        "1.04.2021 19:00",
        "1.04.2021 20:00",
        "1.04.2021 21:00",
        "1.04.2021 22:00",
        "1.04.2021 23:00",
        "2.04.2021 00:00",
        "2.04.2021 01:00",
        "2.04.2021 02:00",
        "2.04.2021 03:00",
        "2.04.2021 04:00",
        "2.04.2021 05:00",
        "2.04.2021 06:00",
        "2.04.2021 07:00",
        "2.04.2021 08:00",
    ];
    const valuesTS = [
        34058,
        32497,
        31271,
        30440,
        30508,
        30936,
        30786,
        32633,
        38639,
        42179,
        43386,
        44395,
        42918,
        43087,
        43457,
        42482,
        41787,
        40871,
        39775,
        40849,
        40330,
        39132,
        38404,
        36656,
        34445,
        32718,
        31475,
        30668,
        30747,
        31062,
        31249,
        32767,
        38762,
    ];

    const data = [];
    for (let ii = 0; ii < xticksTSF.length; ii++) {
        data.push({
            xticks: xticksTSF[ii].toString(),
            value: valuesTS[ii],
        });
    }

    return (
        <div className="custom-shadow2 border-4 mb-10 rounded-lg min-h-[500px]">
            <ResponsiveContainer width="100%" minHeight={500}>
                <LineChart
                    width={500}
                    height={1000}
                    data={data}
                    margin={{
                        top: 30,
                        right: 30,
                        left: 30,
                        bottom: 30,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="xticks"
                        style={{ fontSize: "1rem" }}
                    />
                    <YAxis dataKey="name" domain={[25000, 50000]} />

                    <Line
                        type="monotone"
                        dataKey="value"
                        dot={true}
                        stroke={color}
                        activeDot={{ r: 5 }}
                    />

                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default SalesForecastingChart;
