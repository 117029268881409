import React from "react";
import { useTranslation } from "react-i18next";
const CustomerBehaviorAnalysisRules = () => {
  const { t } = useTranslation();
  const data = [
    [ "1", "[kars.] → [sat.al.]", "0.83", "5", "0.83" ],
    [ "2", "[kars.] → [sep.at.]", "0.83", "5", "0.83" ],
    [ "3", "[kars.] → [sep.at.] ≺ [sat.al.]", "0.83", "5", "0.83" ],
    [ "4", "[kars.] ≺ [sep.at.] → [sat.al.]", "0.83", "5", "0.83" ],
    [ "5", "[sep.at.] → [sat.al.]", "1.00", "5", "1.00" ],    
    ]

  return (
    <div className="w-[50%] p-6 flex flex-col items-center bg-gray-50">
      <div className="border border-gray-400 rounded-lg p-6 w-full bg-white shadow-lg">
        {/* Header */}
        <h2 className="text-xl font-bold text-blue-600 text-center">{t("Kurallar")}</h2>
        
        {/* Table Section */}
        <div className="overflow-x-auto">
          <table className="text-lg w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-sky-700 text-sky-100">
                <th className="border border-gray-300 p-2"> {t("No.")}</th>
                <th className="border border-gray-300 p-2">{t("Kural")}</th>
                <th className="border border-gray-300 p-2">{t("Guven")}</th>
                <th className="border border-gray-300 p-2">{t("Sıklık_Oranı")}</th>
                <th className="border border-gray-300 p-2">{t("Ilgi")}</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index} className="text-gray-700">
                  {row.map((cell, cellIndex) => (
                    <td
                      key={cellIndex}
                      className={`text-lg text-center border border-gray-300 p-2`}
                    >
                      {cell}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        
      </div>

    </div>
  );
};

export default CustomerBehaviorAnalysisRules;
