import React from "react";
import { useTranslation } from "react-i18next";
import PageHeaderSolutions from "../components/PageHeaderSolutions";
import { CaretRightFilled } from "@ant-design/icons";
import IndustryBanner from "../appareas/IndustryBanner";
import RootCauseAnalysisDataset from "./charts/RootCauseAnalysisDataset"
import RootCauseAnalysisRulesGraph from "./charts/RootCauseAnalysisRulesGraph"

const RootCauseAnalysis = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeaderSolutions></PageHeaderSolutions>
      <section className="mt-24 w-full h-full relative bg-center bg-cover p-5 border-2 border-blue-900 rounded-lg bg-white">
        <div> <IndustryBanner></IndustryBanner> </div>
        <div className="items-center text-center">
          <h3 className="mt-2 text-blue-900 text-4xl font-semibold mb-2">
            {t("root_cause_analysis")}
          </h3>
        </div>

        <p className="text-2xl">
          <CaretRightFilled />
          {t("root_cause_analysis_description1")}
        </p>
        


        
<div className="flex ">
       <RootCauseAnalysisDataset></RootCauseAnalysisDataset>
       <RootCauseAnalysisRulesGraph></RootCauseAnalysisRulesGraph>
</div>
     
      </section>
    </>
  );
};

export default RootCauseAnalysis;
