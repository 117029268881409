import React from 'react';
import { useTranslation } from 'react-i18next';
import BackgroudImage from "../assets/images/bg/5.jpg";
import PageHeaderSolutions from "../components/PageHeaderSolutions";
import EcommerceApps from "../appareas/EcommerceApps"
import IndustryApps from "../appareas/IndustryApps"
import TelecommunicationApps from "../appareas/TelecommunicationApps"
import SecurityApps from "../appareas/SecurityApps"
import HealthcareApps from "../appareas/HealthcareApps"



const Solutions = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeaderSolutions></PageHeaderSolutions>
      <section className="py-16 lg:py-48 w-full table relative bg-center bg-cover pl-5"
        style={{ backgroundImage: `url(${BackgroudImage})` }}
        id="home"
      >
        <div className='items-center text-center'>
              <h3 className="text-slate-100 text-6xl font-semibold mb-20">{t("perfect_solutions")}</h3>
        </div>

        <EcommerceApps></EcommerceApps>
        <IndustryApps></IndustryApps>
        <TelecommunicationApps></TelecommunicationApps>
        <SecurityApps></SecurityApps>
        <HealthcareApps></HealthcareApps>

      </section>
    </>
  );
};

export default Solutions;
