import React from "react";
import deep_learning from "../../assets/images/applications/deep_learning.png"
import { FaArrowRightLong } from "react-icons/fa6";
import { useTranslation } from "react-i18next";

const factors = [
  { name: "İşlem Sayısı", value: 8 },
  { name: "İşlem Yönü", value: 4 },
  { name: "İşlem Uzunluğu", value: 3 },
  { name: "İşlem Toleransı", value: 1 },
  { name: "İşlem Zorluğu", value: 4 },
  { name: "İşlem Açısı", value: 3 },
  { name: "İşlem Yarıçapı", value: 3 },
  { name: "İşlem Tipi", value: 1.2 },
  { name: "Makine Yaşı", value: 4 },
  { name: "Makinenin Etkinliği", value: 4 },
  { name: "Operatörün Yetkinliği", value: 6 },
  { name: "Mevsimsellik/Hava Sıcaklığı", value: 2 },
  { name: "Malzeme Eni", value: 4 },
  { name: "Malzeme Boyu", value: 3.4 },
  { name: "Malzeme Kalınlığı", value: 3 },
  { name: "Malzeme Ağırlığı", value: 4 },
  { name: "Malzeme Cinsi", value: 3 },
  { name: "Malzeme Kalitesi", value: 4.7 },
  { name: "Malzeme Tedarikçisi", value: 7 }
];

const RegressionModelChart = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col p-6">

      <div className="flex items-center">

        <div className="pl-8 border-[1px] border-[#e4e7ec] rounded-[10px] min-h-[191px]">
          <h2 className="text-center text-blue-600 text-2xl font-bold mb-4">Model</h2>
          <div className="flex justify-center">
            <div className="flex-col">
              {factors.map((val) => {
                return (
                  <div className="items-center text-center flex justify-end pt-2 text-sky-600 bold">
                    {val.name}
                    <div className="pl-1"></div>
                    <FaArrowRightLong style={{ color: "#075985" }} />

                  </div>
                );
              })}
              <div className="w-[6px] h-[10px]"></div>
            </div>
            <div
              className={`w-[200px] bg-white-200 border-sky-800 border-4 items-center text-center flex justify-center text-xl font-bold rounded-lg shadow-xl`}
            >
              <img
                src={deep_learning}
                alt="deep_learning"
                className="h-32"
              />
            </div>
            <div className="items-center text-center flex justify-center">
              <FaArrowRightLong style={{ color: "#075985" }} />
              <div className="ml-2 mr-2 text-sky-600 bold">İşlem Süresi</div>
            </div>
          </div>
        </div>


        <div className="pl-16"></div>

        {/* Factor Effects */}
        <div className="pl-32 border-[1px] border-[#e4e7ec] rounded-[10px] min-h-[191px]">
          <h2 className="text-blue-600 text-2xl font-bold mb-4"> {t("Faktör_Etki_Değerleri")}</h2>
          {factors.map((factor, index) => (
            <div key={index} className="flex items-center mb-2">
              <div className="w-40 text-gray-700 text-sm">{factor.name}</div>
              <div className="w-32 bg-gray-200 rounded-full h-4 ml-2 relative">
                <div
                  className="bg-orange-400 h-4 rounded-full"
                  style={{ width: `${factor.value * 10}%` }}
                ></div>
              </div>
              <div className="ml-2 text-gray-700 font-medium">{factor.value}%</div>
            </div>
          ))}
        </div>

      </div>
    </div>
  );
};

export default RegressionModelChart;
