import BackgroudImage from "../assets/images/bg/choose-us-bg3.jpg";
import AppCard from "./AppCard"
import SecurityBanner from "./SecurityBanner";
import { useTranslation } from 'react-i18next';



const SecurityApps = () =>  {
  const { t } = useTranslation();
  return (

    <div className="flex-col" style={{ backgroundImage: `url(${BackgroudImage})` }}>

      <div> <SecurityBanner></SecurityBanner> </div>


      <div className="flex p-8 gap-8">
            <AppCard title={t("crime_investigation")} description={t("crime_investigation_description")} link="CrimeInvestigation"></AppCard>
            <AppCard title={t("fraud_detection")} description={t("fraud_detection_description")} link="FraudDetection"></AppCard>
            <AppCard title={t("intrusion_detection")} description={t("intrusion_detection_description")} link="IntrusionDetection"></AppCard>           
      </div>

    </div>
    
  );
}
export default SecurityApps;
