import {
    ResponsiveContainer,
    XAxis,
    YAxis,
    LineChart,
    Line,
    CartesianGrid,
    ReferenceLine,
    Label,
} from "recharts";


const AnomalyDetectionChart = () => {

    const valuesTS = [

        ["3.8266022614726007","43.8266022614726","35.306409045890405","12.704038643116439",],
        ["3.5022986230875093","43.50229862308751","34.009194492350034","12.898620826147495",],
        ["4.091410026722369","44.09141002672237","36.36564010688947","12.545153983966578",],
        ["2.8995830189410565","42.89958301894106","31.598332075764226","13.260250188635366",],
        ["4.750460060559681","44.75046006055968","39.00184024223873","12.14972396366419",],
        ["0.9246350223440578","40.924635022344056","23.69854008937623","14.445218986593565",],
        ["2.9392156459965726","42.93921564599657","31.75686258398629","13.236470612402057",],
        ["4.724529900723215","44.72452990072321","38.89811960289286","12.16528205956607",],
        ["1.0151440422149252","41.01514404221493","24.0605761688597","14.390913574671044",],
        ["3.155258172011531","43.15525817201153","32.62103268804613","13.106845096793082",],
        ["4.540096647849443","44.540096647849445","38.16038659139777","12.275942011290335",],
        ["1.6286444205981045","41.62864442059811","26.514577682392417","14.022813347641137",],
        ["4.282776774311246","44.282776774311245","37.131107097244985","12.430333935413252",],
        ["2.3959314389215813","42.39593143892158","29.583725755686324","13.56244113664705",],
        ["4.866552392984152","44.866552392984154","39.46620957193661","12.080068564209508",],
        ["0.5065552215835654","40.50655522158357","22.02622088633426","14.696066867049861",],
        ["1.7754187740153162","41.775418774015314","27.101675096061264","13.93474873559081",],
        ["4.465485996621416","44.46548599662142","37.86194398648566","12.320708402027151",],
        ["1.8617545417263925","41.86175454172639","27.447018166905572","13.882947274964165",],
        ["4.557261333294628","44.557261333294626","38.22904533317851","12.265643200023224",],
        ["1.5737871290940306","41.57378712909403","26.295148516376123","14.055727722543581",],
        ["4.205861179859135","44.205861179859134","36.82344471943654","12.47648329208452",],
        ["2.605229355338688","42.605229355338686","30.42091742135475","13.436862386796786",],
        ["4.866362890564503","44.8663628905645","39.46545156225801","12.080182265661298",],
        ["0.5072548027241842","40.507254802724184","22.029019210896738","14.695647118365489",],
        ["1.7775939314126528","41.77759393141265","27.110375725650613","13.933443641152408",],
        ["4.467940988213174","44.46794098821317","37.871763952852696","12.319235407072096",],
        ["1.8542224481902327","41.854222448190235","27.41688979276093","13.88746653108586",],
        ["4.549717655791295","44.5497176557913","38.19887062316518","12.270169406525223",],
        ["1.5979528745992002","41.5979528745992","26.3918114983968","14.04122827524048",],
        ["4.2403225671738","44.2403225671738","36.9612902686952","12.45580645969572",],
        ["2.5125963425047644","42.512596342504764","30.050385370019058","13.49244219449714",],
        ["4.874876239081292","44.87487623908129","39.49950495632517","12.075074256551225",],
        ["0.47577102225673773","40.47577102225674","21.90308408902695","14.714537386645958",],
        ["1.6789476956182845","41.678947695618284","26.71579078247314","13.99263138262903",],
        ["4.34918102850619","44.34918102850619","37.396724114024764","12.390491382896286",],
        ["2.207813028573976","42.20781302857397","28.831252114295904","13.675312182855615",],
        ["4.808408883508532","44.808408883508534","39.23363553403413","12.114954669894882",],
        ["0.7185737727003363","40.71857377270034","22.874295090801347","14.568855736379799",],
        ["2.399686065417332","42.399686065417335","29.598744261669328","13.560188360749601",],
        ["4.867150949332264","44.86715094933226","39.46860379732905","12.07970943040064",],
        ["0.5043451787987818","40.50434517879878","22.017380715195127","14.697392892720732",],
        ["1.7685422310007404","41.76854223100074","27.07416892400296","13.938874661399556",],
        ["4.457676235093087","44.45767623509309","37.83070494037235","12.325394258944147",],
        ["1.8856529316703678","41.885652931670364","27.54261172668147","13.86860824099778",],
        ["4.580610590115134","44.580610590115135","38.322442360460535","12.25163364593092",],
        ["1.4984264663945899","41.49842646639459","25.99370586557836","14.100944120163247",],
        ["4.0925433562893145","44.09254335628931","36.37017342515726","12.54447398622641",],
        ["2.8967684135042377","42.89676841350424","31.58707365401695","13.261938951897458",],
        ["4.752208364315358","44.75220836431536","39.00883345726143","12.148674981410785",],
        ["0.9184948372921933","40.918494837292194","23.673979349168775","14.448903097624683",],
        ["2.924096307856272","42.924096307856274","31.69638523142509","13.245542215286237",],
        ["4.734711010896889","44.73471101089689","38.93884404358755","12.159173393461867",],
        ["0.9797320242654405","40.97973202426544","23.918928097061762","14.412160785440737",],
        ["3.0722525199256365","43.072252519925634","32.28901007970255","13.156648488044619",],
        ["4.619571101682231","44.61957110168223","38.478284406728925","12.22825733899066",],
        ["1.3707863090325854","41.37078630903259","25.48314523613034","14.177528214580448",],
        ["3.8804036233027626","43.88040362330276","35.52161449321105","12.671757826018343",],
        ["3.388698952682631","43.38869895268263","33.554795810730525","12.96678062839042",],
        ["4.25896705377062","44.25896705377062","37.03586821508248","12.444619767737628",],
        ["2.461707224924614","42.461707224924616","29.84682889969846","13.522975665045232",],
        ["4.87385625743408","44.87385625743408","39.495425029736325","12.07568624553955",],
        ["0.47954904585202734","40.479549045852025","21.91819618340811","14.712270572488784",],
        ["1.6908667946683864","41.69086679466839","26.763467178673544","13.985479923198968",],
        ["4.364336695703235","44.36433669570324","37.45734678281294","12.381397982578058",],
        ["2.1639139743423867","42.163913974342385","28.65565589736955","13.701651615394567",],
        ["4.786896023018983","44.78689602301898","39.14758409207593","12.12786238618861",],
        ["0.7956831323219691","40.79568313232197","23.182732529287875","14.522590120606818",],
        ["2.6093371313475533","42.60933713134755","30.437348525390213","13.434397721191468",],
        ["4.865675405532776","44.86567540553278","39.46270162213111","12.080594756680334",],
        ["0.5097923030127215","40.50979230301272","22.039169212050886","14.694124618192367",],
        ["1.7854771918250225","41.785477191825024","27.14190876730009","13.928713684904986",],
        ["4.476776582146241","44.47677658214624","37.90710632858496","12.313934050712255",],
        ["1.8270363885370167","41.82703638853702","27.308145554148066","13.90377816687779",],
        ["4.5217535825644255","44.52175358256443","38.0870143302577","12.286947850461345",],
        ["1.6867597120825717","41.68675971208257","26.747038848330288","13.987944172750456",],
        ["4.359139382604223","44.359139382604226","37.43655753041689","12.384516370437467",],
        ["2.179008589718991","42.17900858971899","28.716034358875966","13.692594846168605",],
        ["4.7946323213301305","44.79463232133013","39.17852928532052","12.123220607201922",],
        ["0.768036757727535","40.768036757727536","23.07214703091014","14.539177945363479",],
        ["2.5352365953852996","42.5352365953853","30.140946381541198","13.47885804276882",],
        ["4.874031538229609","44.87403153822961","39.496126152918436","12.075581077062235",],
        ["0.47889991928310216","40.4788999192831","21.91559967713241","14.71266004843014",],
        ["1.6888204817063963","41.688820481706394","26.755281926825585","13.986707710976162",],
        ["4.361750475498746","44.361750475498745","37.44700190199498","12.382949714700752",],
        ["2.1714304302445497","42.17143043024455","28.6857217209782","13.69714174185327",],
        ["4.790792789507961","44.79079278950796","39.163171158031844","12.125524326295224",],
        ["0.7817693485199029","40.7817693485199","23.127077394079613","14.530938390888059",],
        ["2.572193074085139","42.57219307408514","30.288772296340554","13.456684155548917",],
        ["4.870934764842227","44.870934764842225","39.48373905936891","12.077439141094663",],
        ["0.4903613058727747","40.490361305872774","21.961445223491097","14.705783216476336",],
        ["1.7248548088719642","41.72485480887197","26.899419235487855","13.965087114676821",],
        ["4.406336947483443","44.406336947483446","37.62534778993377","12.356197831509935",],
        ["2.040385965274415","42.04038596527442","28.161543861097663","13.775768420835352",],
        ["4.71022885248495","44.71022885248495","38.8409154099398","12.173862688509029",],
        ["1.0646129673215874","41.06461296732159","24.25845186928635","14.361232219607048",],
        ["3.267937971806556","43.26793797180655","33.07175188722623","13.039237216916067",],
        ["4.4150115917369535","44.415011591736956","37.66004636694781","12.350993044957828",],
        ["2.0145298707402173","42.01452987074022","28.05811948296087","13.79128207755587",],
        ["4.691168627805261","44.69116862780526","38.764674511221045","12.185298823316844",],
    ];

    const data = [];
    for (let ii = 0; ii < valuesTS.length; ii++) {
        data.push({
            value1: valuesTS[ii][0],
            value2: valuesTS[ii][1],
            value3: valuesTS[ii][2],
            value4: valuesTS[ii][3],
        });
    }

    return (
        <div className="custom-shadow2 border-4 mb-10 rounded-lg min-h-[500px]">
            <ResponsiveContainer width="100%" minHeight={500}>
                <LineChart
                    width={500}
                    height={1000}
                    data={data}
                    margin={{
                        top: 30,
                        right: 30,
                        left: 30,
                        bottom: 30,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        style={{ fontSize: "1rem" }}
                    />

                    <YAxis dataKey="name" domain={[0, 50]} tick={{ fill: '#ffffff'}}>
                    <Label value="IoTx" offset={370} position="insideTop"  style={{ fill: "blue" }}/>
                    <Label value="IoTy" offset={300} position="insideTop"  style={{ fill: "black" }}/>
                    <Label value="IoTz" offset={120} position="insideTop"  style={{ fill: "green" }}/>
                    <Label value="sensor" offset={40} position="insideTop"  style={{ fill: "orange" }}/>
                    </YAxis>

                    <Line type="monotone" dataKey="value1" dot={false} stroke="blue"/>
                    <Line type="monotone" dataKey="value2" dot={false} stroke="orange"/>
                    <Line type="monotone" dataKey="value3" dot={false} stroke="green"/>
                    <Line type="monotone" dataKey="value4" dot={false} stroke="black"/>

                    <ReferenceLine x={33} stroke="#f9928a" strokeWidth="3">
                        <Label value="anomaly" offset={5} position="top"/>
                    </ReferenceLine>

                    <ReferenceLine x={62} stroke="#f9928a" strokeWidth="3">
                        <Label value="anomaly" offset={5} position="top" />
                    </ReferenceLine>

                    <ReferenceLine x={90} stroke="#f9928a" strokeWidth="3">
                        <Label value="anomaly" offset={5} position="top" />
                    </ReferenceLine>

                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default AnomalyDetectionChart;
