import React from "react";
import { useTranslation } from "react-i18next";
const DiagnosticsDataset = () => {
  const { t } = useTranslation();
  const data = [
    ["high BP ","high cholesterol","yes cholesterol check in 5 years","40.0","yes","no","no","no","no","yes","no","yes","no","5.0","18.0","15.0","yes","female","9.0","3.0","High school graduate","no diabetes",],
    ["no high BP ","no high cholesterol","no cholesterol check in 5 years","25.0","yes","no","no","yes","no","no","no","no","yes","3.0","0.0","0.0","no","female","7.0","1.0","College graduate","no diabetes",],
    ["high BP ","high cholesterol","yes cholesterol check in 5 years","28.0","no","no","no","no","yes","no","no","yes","yes","5.0","30.0","30.0","yes","female","9.0","8.0","High school graduate","no diabetes",],
    ["high BP ","no high cholesterol","yes cholesterol check in 5 years","27.0","no","no","no","yes","yes","yes","no","yes","no","2.0","0.0","0.0","no","female","11.0","6.0","Some high school","no diabetes",],
    ["high BP ","high cholesterol","yes cholesterol check in 5 years","33.0","no","no","no","no","no","yes","no","yes","no","3.0","3.0","0.0","no","male","7.0","7.0","High school graduate","prediabetes ",],
    ["high BP ","high cholesterol","yes cholesterol check in 5 years","25.0","yes","no","no","yes","yes","yes","no","yes","no","2.0","0.0","2.0","no","male","10.0","8.0","College graduate","no diabetes",],
    ["high BP ","no high cholesterol","yes cholesterol check in 5 years","30.0","yes","no","no","no","no","no","no","yes","no","3.0","0.0","14.0","no","female","9.0","7.0","College graduate","no diabetes",],
    ["high BP ","high cholesterol","yes cholesterol check in 5 years","25.0","yes","no","no","yes","no","yes","no","yes","no","3.0","0.0","0.0","yes","female","11.0","4.0","High school graduate","no diabetes",],
    ["high BP ","high cholesterol","yes cholesterol check in 5 years","30.0","yes","no","yes","no","yes","yes","no","yes","no","5.0","30.0","30.0","yes","female","9.0","1.0","Some college or technical school","diabetes",],
    ["no high BP ","no high cholesterol","yes cholesterol check in 5 years","24.0","no","no","no","no","no","yes","no","yes","no","2.0","0.0","0.0","no","male","8.0","3.0","High school graduate","no diabetes",],
    ["no high BP ","no high cholesterol","yes cholesterol check in 5 years","25.0","yes","no","no","yes","yes","yes","no","yes","no","3.0","0.0","0.0","no","male","13.0","8.0","College graduate","diabetes",],
    ["high BP ","high cholesterol","yes cholesterol check in 5 years","34.0","yes","no","no","no","yes","yes","no","yes","no","3.0","0.0","30.0","yes","female","10.0","1.0","Some college or technical school","no diabetes",],
    ["no high BP ","no high cholesterol","yes cholesterol check in 5 years","26.0","yes","no","no","no","no","yes","no","yes","no","3.0","0.0","15.0","no","female","7.0","7.0","Some college or technical school","no diabetes",],
    ["high BP ","high cholesterol","yes cholesterol check in 5 years","28.0","no","no","no","no","no","yes","no","yes","no","4.0","0.0","0.0","yes","female","11.0","6.0","High school graduate","diabetes",],
    ["no high BP ","high cholesterol","yes cholesterol check in 5 years","33.0","yes","yes","no","yes","no","yes","no","yes","yes","4.0","30.0","28.0","no","female","4.0","2.0","College graduate","no diabetes",],
    
     ]


  return (
    <div className="p-6 flex flex-col items-center bg-gray-50 ">
      <div className="border border-gray-400 rounded-lg p-6 w-full bg-white shadow-lg">
        {/* Header */}
        <h2 className="text-xl font-bold text-blue-600 text-center mb-6">{t("Veriseti")}</h2>
        
        {/* Table Section */}
        <div className="overflow-x-auto">
          <table className="text-sm w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-sky-700 text-sky-100">
                <th className="border border-gray-300 p-2"> HighBP</th>
                <th className="border border-gray-300 p-2"> HighChol</th>
                <th className="border border-gray-300 p-2"> CholCheck</th>
                <th className="border border-gray-300 p-2"> BMI</th>
                <th className="border border-gray-300 p-2"> Smoker</th>
                <th className="border border-gray-300 p-2"> Stroke</th>
                <th className="border border-gray-300 p-2"> HeartDiseaseorAttack</th>
                <th className="border border-gray-300 p-2"> PhysActivity</th>
                <th className="border border-gray-300 p-2"> Fruits</th>
                <th className="border border-gray-300 p-2"> Veggies</th>
                <th className="border border-gray-300 p-2"> HvyAlcoholConsump</th>
                <th className="border border-gray-300 p-2"> AnyHealthcare</th>
                <th className="border border-gray-300 p-2"> NoDocbcCost</th>
                <th className="border border-gray-300 p-2"> GenHlth</th>
                <th className="border border-gray-300 p-2"> MentHlth</th>
                <th className="border border-gray-300 p-2"> PhysHlth</th>
                <th className="border border-gray-300 p-2"> DiffWalk</th>
                <th className="border border-gray-300 p-2"> Sex</th>
                <th className="border border-gray-300 p-2"> Age</th>
                <th className="border border-gray-300 p-2"> Income</th>
                <th className="border border-gray-300 p-2"> Education</th>
                <th className="border border-gray-300 p-2"> diabetes</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index} className="text-gray-700">
                  {row.map((cell, cellIndex) => (
                    <td
                      key={cellIndex}
                      className={`text-sm text-center border border-gray-300 p-2 ${cellIndex===21?(cell==='no diabetes'? "text-green-600 font-bold":"text-red-600 font-bold"):""} `}
                    >
                      {cell}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        
      </div>

    </div>
  );
};

export default DiagnosticsDataset;
