import React from 'react';
import TelecommunicationApps from "./TelecommunicationApps";
import PageHeader from "../components/PageHeader"

const Telecommunication = () => {
  return (
    <div>
        <PageHeader/>
        <TelecommunicationApps/>
    </div>          
  );
};

export default Telecommunication;
