import { Link } from "react-router-dom";
import SecurityImage from "../assets/images/applications/security.png";
import { CaretRightFilled } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
const AppCardSecurity = () => {
  const { t } = useTranslation();
  return (
    <div className="text-3xl w-[90%] border-4 border-orange-200 rounded-3xl shadow-2xl h-full bg-slate-50 opacity-80 max-w-[50%]   hover:bg-slate-200 hover:scale-[1.02] duration-300">
      <a href="/solutions/security">
        <div className="pb-2 font-bold text-gray-900 items-center m-1 opacity-90 flex">
          <img src={SecurityImage} className="w-16 h-16 p-3" alt="" />
          {t("security_uppercase")}
        </div>
        <div className="border-2 border-orange-200"></div>
        <div className="text-2xl p-3 m-1 opacity-90 text-justify">
          {/* {t("security_description")} */}
          <p className="text-2xl p-1 m-1 font-semibold">
            <CaretRightFilled />
            {t("crime_investigation")}
          </p>
          <p className="text-2xl p-1 m-1 font-semibold">
            <CaretRightFilled />
            {t("fraud_detection")}
          </p>
          <p className="text-2xl p-1 m-1 font-semibold">
            <CaretRightFilled />
            {t("intrusion_detection")}
          </p>
          <div className="text-[#000fff] pt-8">
            <Link to="/solutions/security" className="flex">
              {t("Read_More")}...
            </Link>
          </div>
        </div>
      </a>
    </div>
  );
};
export default AppCardSecurity;
