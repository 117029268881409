import React from "react";
import { useTranslation } from "react-i18next";
const AlarmAnalysisPatternsPatterns = () => {
  const { t } = useTranslation();
  const data = [
    [ "1", "[EVTE07-81625-VNPLZ-01&npe.dds.VfxSessionDown | EVTE08-92994-YUATO-01&npe.dds.VfxSessionDown | FMTE07-20866-BEMFV-01&npe.dds.VfxSessionDown]", "3", "1617353", "0.924" ],
    [ "2", "[EVTE07-81625-VNPLZ-01&npe.dds.VfxSessionDown | EVTE08-92994-YUATO-01&npe.dds.VfxSessionDown]", "2", "1657728", "0.947" ],
    [ "3", "[EVTE07-81625-VNPLZ-01&npe.dds.VfxSessionDown | FMTE07-20866-BEMFV-01&npe.dds.VfxSessionDown]", "2", "1642277", "0.938" ],
    [ "4", "[EVTE08-92994-YUATO-01&npe.dds.VfxSessionDown | FMTE07-20866-BEMFV-01&npe.dds.VfxSessionDown]", "2", "1646199", "0.940" ],
  
    ]

  return (
    <div className="w-[50%] p-6 flex flex-col items-center bg-gray-50">
      <div className="border border-gray-400 rounded-lg p-6 w-full bg-white shadow-lg">
        {/* Header */}
        <h2 className="text-xl font-bold text-blue-600 text-center"> {t("Sık_Alarm_Setleri")}</h2>
        
        {/* Table Section */}
        <div className="overflow-x-auto">
          <table className="text-lg w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-sky-700 text-sky-100">
                <th className="border border-gray-300 p-2">No.</th>
                <th className="border border-gray-300 p-2">{t("Alarm_Seti")}</th>
                <th className="border border-gray-300 p-2">{t("Uzunluk")}</th>
                <th className="border border-gray-300 p-2">{t("Sıklık_Sayısı")}</th>
                <th className="border border-gray-300 p-2">{t("Sıklık_Oranı")}</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index} className="text-gray-700">
                  {row.map((cell, cellIndex) => (
                    <td
                      key={cellIndex}
                      className={`text-lg text-center border border-gray-300 p-2`}
                    >
                      {cell}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        
      </div>

    </div>
  );
};

export default AlarmAnalysisPatternsPatterns;
