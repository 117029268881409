import { Link } from "react-router-dom";
import EcommerceImage from "../assets/images/applications/e-commerce.png";
import {CaretRightFilled} from "@ant-design/icons"
import { useTranslation } from 'react-i18next';
const AppCardEcommerce = () =>  {
    const { t } = useTranslation();
  return (

      <div className="text-3xl w-[90%] border-4 border-orange-200 rounded-3xl shadow-2xl h-full bg-slate-50 opacity-80 max-w-[50%]   hover:bg-slate-200 hover:scale-[1.02] duration-300">
        <a href="/solutions/ecommerce">
        <div className="pb-2 font-bold text-gray-900 items-center m-1 opacity-90 flex">
        <img src={EcommerceImage} className='w-16 h-16 p-3' alt=""/>
        {t("e_commerce_uppercase")}
        </div>
        <div className="border-2 border-orange-200"></div>
        <div className="text-2xl p-3 m-1 opacity-90 text-justify">
          {/* {t("e_commerce_description")} */}

          <p className="text-2xl p-1 m-1 font-semibold"><CaretRightFilled/>{t("sales_forecasting")}</p>
          <p className="text-2xl p-1 m-1 font-semibold"><CaretRightFilled/>{t("market_basket_analysis")}</p>
          <p className="text-2xl p-1 m-1 font-semibold"><CaretRightFilled/>{t("customer_behavior_analysis")}</p>
          <p className="text-2xl p-1 m-1 font-semibold"><CaretRightFilled/>{t("recommender_systems")}</p>
          <p className="text-2xl p-1 m-1 font-semibold"><CaretRightFilled/>{t("loyalty_program_development")}</p>
          <p className="text-2xl p-1 m-1 font-semibold"><CaretRightFilled/>{t("customer_churn_analysis")}</p>
          
          <div className="text-[#0000ff] pt-8">
            <Link to="/solutions/ecommerce" className="flex">
            {t("Read_More")}...
            </Link>
          </div>
        </div>        
        </a>

      </div>
      
  );
}
export default AppCardEcommerce;
