import BackgroudImage from "../assets/images/bg/choose-us-bg3.jpg";
import AppCard from "./AppCard"
import EcommerceBanner from "./EcommerceBanner";
import { useTranslation } from 'react-i18next';

const EcommerceApps = () =>  {
  const { t } = useTranslation();
  return (

    <div className="flex-col" style={{ backgroundImage: `url(${BackgroudImage})` }}>
      <div> <EcommerceBanner></EcommerceBanner> </div>

      <div className="flex p-8 gap-8">
            <AppCard title={t("sales_forecasting")} description={t("sales_forecasting_description")} link="SalesForecasting"></AppCard>
            <AppCard title={t("market_basket_analysis")} description={t("market_basket_analysis_description")} link="MarketBasketAnalysis"></AppCard>
            <AppCard title={t("customer_behavior_analysis")} description={t("customer_behavior_analysis_description")} link="CustomerBehaviorAnalysis"></AppCard>
      </div>


      <div className="flex p-8 gap-8">
            <AppCard title={t("recommender_systems")} description={t("recommender_systems_description")} link="RecommenderSystems"></AppCard>
            <AppCard title={t("loyalty_program_development")} description={t("loyalty_program_development_description")} link="LoyaltyProgramDevelopment"></AppCard>
            <AppCard title={t("customer_churn_analysis")} description={t("customer_churn_analysis_description")} link="CustomerChurnAnalysis"></AppCard>
      </div>


    </div>
    
  );
}
export default EcommerceApps;
