import React from "react";
import { useTranslation } from "react-i18next";
import PageHeaderSolutions from "../components/PageHeaderSolutions";
import { CaretRightFilled } from "@ant-design/icons";
//import customer_behavior_analysis from "../assets/images/applications/customer_behavior_analysis.jpg";
import EcommerceBanner from "../appareas/EcommerceBanner";
import CustomerBehaviorAnalysisDataset from "./charts/CustomerBehaviorAnalysisDataset"
import CustomerBehaviorAnalysisPatterns from "./charts/CustomerBehaviorAnalysisPatterns"
import CustomerBehaviorAnalysisRules from "./charts/CustomerBehaviorAnalysisRules"
import CustomerBehaviorAnalysisRulesGraph from "./charts/CustomerBehaviorAnalysisRulesGraph"

const CustomerBehaviorAnalysis = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeaderSolutions></PageHeaderSolutions>
      <section className="mt-24 w-full h-full relative bg-center bg-cover p-5 border-2 border-blue-900 rounded-lg bg-white">
        <div> <EcommerceBanner></EcommerceBanner> </div>
        <div className="items-center text-center">
          <h3 className="mt-2 text-blue-900 text-4xl font-semibold mb-2">
            {t("customer_behavior_analysis")}
          </h3>
        </div>

        <p className="text-2xl">
          <CaretRightFilled />
          {t("customer_behavior_analysis_description")}
        </p>

        {/* <img
          src={customer_behavior_analysis}
          width={1200} height={1200}
          alt="customer_behavior_analysis"
        /> */}

        <CustomerBehaviorAnalysisDataset></CustomerBehaviorAnalysisDataset>
        <CustomerBehaviorAnalysisPatterns></CustomerBehaviorAnalysisPatterns>
        <div className="flex">
        <CustomerBehaviorAnalysisRules></CustomerBehaviorAnalysisRules>
        <CustomerBehaviorAnalysisRulesGraph></CustomerBehaviorAnalysisRulesGraph>
        </div>


      </section>
    </>
  );
};

export default CustomerBehaviorAnalysis;
