import React from "react";
import { useTranslation } from "react-i18next";
import PageHeaderSolutions from "../components/PageHeaderSolutions";
import { CaretRightFilled } from "@ant-design/icons";
import TelecommunicationBanner from "../appareas/TelecommunicationBanner";
import AnomalyDetectionTelcoChart from "./charts/AnomalyDetectionTelcoChart"

const AnomalyDetectionTelco = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeaderSolutions></PageHeaderSolutions>
      <section className="mt-24 w-full h-full relative bg-center bg-cover p-5 border-2 border-blue-900 rounded-lg bg-white">
        <div> <TelecommunicationBanner></TelecommunicationBanner> </div>
        <div className="items-center text-center">
          <h3 className="mt-2 text-blue-900 text-4xl font-semibold mb-2">
            {t("anomaly_detection")}
          </h3>
        </div>

        <p className="text-2xl">
          <CaretRightFilled />
          {t("anomaly_detectionTelco_description1")}
        </p>

<AnomalyDetectionTelcoChart></AnomalyDetectionTelcoChart>

      </section>
    </>
  );
};

export default AnomalyDetectionTelco;
