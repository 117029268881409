import React from "react";
import { useTranslation } from "react-i18next";
const CustomerChurnAnalysisDataset = () => {
  const { t } = useTranslation();
  const data = [
    ["7590-VHVEG","Female","Yes","No","1","No","No phone service","DSL","No","Month-to-month","Electronic check","29.85","29.85","No"],
    ["5575-GNVDE","Male","No","No","34","Yes","No","DSL","Yes","One year","Mailed check","56.95","1889.5","No"],
    ["3668-QPYBK","Male","No","No","2","Yes","No","DSL","Yes","Month-to-month","Mailed check","53.85","108.15","Yes"],
    ["7795-CFOCW","Male","No","No","45","No","No phone service","DSL","Yes","One year","Bank transfer (automatic)","42.3","1840.75","No"],
    ["9237-HQITU","Female","No","No","2","Yes","No","Fiber optic","No","Month-to-month","Electronic check","70.7","151.65","Yes"],
    ["9305-CDSKC","Female","No","No","8","Yes","Yes","Fiber optic","No","Month-to-month","Electronic check","99.65","820.5","Yes"],
    ["1452-KIOVK","Male","No","Yes","22","Yes","Yes","Fiber optic","No","Month-to-month","Credit card (automatic)","89.1","1949.4","No"],
    ["6713-OKOMC","Female","No","No","10","No","No phone service","DSL","Yes","Month-to-month","Mailed check","29.75","301.9","No"],
    ["7892-POOKP","Female","Yes","No","28","Yes","Yes","Fiber optic","No","Month-to-month","Electronic check","104.8","3046.05","Yes"],
    ["6388-TABGU","Male","No","Yes","62","Yes","No","DSL","Yes","One year","Bank transfer (automatic)","56.15","3487.95","No"],
    ["9763-GRSKD","Male","Yes","Yes","13","Yes","No","DSL","Yes","Month-to-month","Mailed check","49.95","587.45","No"],
    ["7469-LKBCI","Male","No","No","16","Yes","No","No","No internet service","Two year","Credit card (automatic)","18.95","326.8","No"],
    ["8091-TTVAX","Male","Yes","No","58","Yes","Yes","Fiber optic","No","One year","Credit card (automatic)","100.35","5681.1","No"],
    ["0280-XJGEX","Male","No","No","49","Yes","Yes","Fiber optic","No","Month-to-month","Bank transfer (automatic)","103.7","5036.3","Yes"],
     ]

  return (
    <div className="p-6 flex flex-col items-center bg-gray-50 ">
      <div className="border border-gray-400 rounded-lg p-6 w-full bg-white shadow-lg">
        {/* Header */}
        <h2 className="text-xl font-bold text-blue-600 text-center mb-6">{t("Veriseti")}</h2>
        
        {/* Table Section */}
        <div className="overflow-x-auto">
          <table className="text-sm w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-sky-700 text-sky-100">
                <th className="border border-gray-300 p-2">customerID</th>
                <th className="border border-gray-300 p-2">gender</th>
                <th className="border border-gray-300 p-2">Partner</th>
                <th className="border border-gray-300 p-2">Dependents</th>
                <th className="border border-gray-300 p-2">tenure</th>
                <th className="border border-gray-300 p-2">PhoneService</th>
                <th className="border border-gray-300 p-2">MultipleLines</th>
                <th className="border border-gray-300 p-2">InternetService</th>
                <th className="border border-gray-300 p-2">OnlineSecurity</th>
                <th className="border border-gray-300 p-2">Contract</th>
                <th className="border border-gray-300 p-2">PaymentMethod</th>
                <th className="border border-gray-300 p-2">MonthlyCharges</th>
                <th className="border border-gray-300 p-2">TotalCharges</th>
                <th className="border border-gray-300 p-2">Churn</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index} className="text-gray-700">
                  {row.map((cell, cellIndex) => (
                    <td
                      key={cellIndex}
                      className={`text-sm text-center border border-gray-300 p-2 ${cellIndex===data.length-1?(cell==='Yes'? "text-green-600 font-bold":"text-red-600 font-bold"):""} `}
                    >
                      {cell}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        
      </div>

    </div>
  );
};

export default CustomerChurnAnalysisDataset;
