import React from "react";
import { useTranslation } from "react-i18next";
import PageHeaderSolutions from "../components/PageHeaderSolutions";
import { CaretRightFilled } from "@ant-design/icons";
//import customer_churn_analysis from "../assets/images/applications/customer_churn_analysis.jpg";
import EcommerceBanner from "../appareas/EcommerceBanner";
import CustomerChurnAnalysisDataset from "./charts/CustomerChurnAnalysisDataset"
import CustomerChurnAnalysisModel from "./charts/CustomerChurnAnalysisModel"

const CustomerChurnAnalysis = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeaderSolutions></PageHeaderSolutions>
      <section className="mt-24 w-full h-full relative bg-center bg-cover p-5 border-2 border-blue-900 rounded-lg bg-white">
        <div> <EcommerceBanner></EcommerceBanner> </div>
        <div className="items-center text-center">
          <h3 className="mt-2 text-blue-900 text-4xl font-semibold mb-2">
            {t("customer_churn_analysis")}
          </h3>
        </div>

        <p className="text-2xl">
          <CaretRightFilled />
          {t("customer_churn_analysis_description1")}
        </p>

        <CustomerChurnAnalysisDataset></CustomerChurnAnalysisDataset>
        <CustomerChurnAnalysisModel></CustomerChurnAnalysisModel>

        {/* <img src={customer_churn_analysis} width={1200} height={1200} alt="customer_churn_analysis" /> */}
      
      
      </section>
    </>
  );
};

export default CustomerChurnAnalysis;
