import React from "react";
import { useTranslation } from "react-i18next";
import PageHeaderSolutions from "../components/PageHeaderSolutions";
import { CaretRightFilled } from "@ant-design/icons";
import TelecommunicationBanner from "../appareas/TelecommunicationBanner";
import AlarmAnalysisDataset from "./charts/AlarmAnalysisDataset";
import { FaCircleCheck  } from "react-icons/fa6";
import RootCauseAnalysisTelcoRules from "./charts/RootCauseAnalysisTelcoRules"
import RootCauseAnalysisTelcoRulesGraph from "./charts/RootCauseAnalysisTelcoRulesGraph"


const RootCauseAnalysisTelco = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeaderSolutions></PageHeaderSolutions>
      <section className="mt-24 w-full h-full relative bg-center bg-cover p-5 border-2 border-blue-900 rounded-lg bg-white">
        <div> <TelecommunicationBanner></TelecommunicationBanner> </div>
        <div className="items-center text-center">
          <h3 className="mt-2 text-blue-900 text-4xl font-semibold mb-2">
            {t("root_cause_analysis")}
          </h3>
        </div>

        <p className="text-2xl">
          <CaretRightFilled />
          {t("root_cause_analysisTelco_description1")}
        </p>
        
        <div className="flex">
          <AlarmAnalysisDataset></AlarmAnalysisDataset>
          <div className="m-8 flex flex-col w-[30%]">

            <div className="mb-16">
              <p className="text-3xl text-orange-800 pb-4">{t("VERİ")}</p>
              <ul>
                <li className="text-2xl text-blue-800 pb-4 flex"> <FaCircleCheck className="mt-1 text-orange-300 mr-2"></FaCircleCheck > {t("90_günlük_kayıt")}</li>
                <li className="text-2xl text-blue-800 pb-4 flex"> <FaCircleCheck className="mt-1 text-orange-300 mr-2"></FaCircleCheck > {t("1_milyondan_fazla_kayıt")}</li>
                <li className="text-2xl text-blue-800  pb-4 flex"><FaCircleCheck className="mt-1 text-orange-300 mr-2"></FaCircleCheck > {t("11503_farklı_alarm")}</li>
              </ul>
            </div>

            <div>
              <p className="text-3xl text-orange-800 pb-4">{t("PROBLEM")}</p>
              <ul>
                <li className="text-2xl text-blue-800 pb-4 flex"> {t("AAproblem")}</li>
              </ul>
            </div>

          </div>
        </div>

        <div className="flex">
          <RootCauseAnalysisTelcoRules></RootCauseAnalysisTelcoRules>
          <RootCauseAnalysisTelcoRulesGraph></RootCauseAnalysisTelcoRulesGraph>
          {/* <AlarmAnalysisRulesGraph></AlarmAnalysisRulesGraph> */}
        </div>

        
      </section>
    </>
  );
};

export default RootCauseAnalysisTelco;
